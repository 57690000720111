import React, { useState, useEffect, memo, useRef } from 'react';
import { Modal, Row, Col, Form, Tab } from 'react-bootstrap';
import {
  getRequest,
  postRequest,
  deleteRequest,
} from '../../../utils/axiosRequests';
import { useGetRequest } from '../../../hooks/useGetRequest';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import GroupWithDeleteIcon from '../partials/groupWithDeleteIcon';
import TreatmentPlanGroupForm from './treatmentPlanGroupForm';
import LoadMore from '../partials/loadMore';
import ModalHeader from '../partials/modalHeader';
import SearchArea from '../partials/searchArea';
import TabNav from '../partials/tabNav';
import { useClickAway } from 'react-use';
import { handleGroupData } from '../../../utils/helpers';

const TreatmentPlanModal = ({
  modalTitle,
  selectedTreatmentPlans,
  setSelectedTreatmentPlans,
  selectedTreatmentPlansGroups,
  setSelectedTreatmentPlansGroups,
  isTreatmentPlanModal,
  setIsTreatmentPlanModal,
}) => {
  const [treatmentPlans, setTreatmentPlans] = useState([]);
  const [treatmentPlansInSearch, setTreatmentPlansInSearch] = useState([]);
  const [treatmentPlanGroups, setTreatmentPlanGroups] = useState([]);
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [searchItemName, setSearchItemName] = useState('');
  const perPage = 25;

  useEffect(() => {
    getRequest('treatment-plans/groups')
      .then((data) => {
        setTreatmentPlanGroups(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const { isLoading: isTreatmentPlanLoading, refetch: getTreatmentPlan } =
    useGetRequest(
      'getTreatmentPlan',
      `treatment-plans?page=${currentPage}&perPage=${perPage}`,
      (data) => {
        if (currentPage > 1) {
          setTreatmentPlans([...treatmentPlans, ...data.data]);
        } else {
          setTreatmentPlans(data.data);
          setTotalItem(data.total);
        }
      },
      (e) => {
        console.log(e);
      },
    );

  const selectTreatmentPlan = (item) => {
    const isItemExist = selectedTreatmentPlans?.some(
      (treatmentPlan) => treatmentPlan.name === item.name,
    );
    const updatedTreatmentPlans = isItemExist
      ? selectedTreatmentPlans?.filter(
          (treatmentPlan) => treatmentPlan.name !== item.name,
        )
      : [
          ...selectedTreatmentPlans,
          { name: item.name, date: '', note: '' },
        ];
    setSelectedTreatmentPlans(updatedTreatmentPlans);
  };

  const deleteTreatmentPlan = (treatmentPlanId) => {
    deleteRequest(`treatment-plans/${treatmentPlanId}`)
      .then((data) => {
        setTreatmentPlans(
          treatmentPlans?.filter(
            (treatmentPlan) => treatmentPlan.id !== treatmentPlanId,
          ),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteTreatmentPlanGroup = (groupId) => {
    deleteRequest(`treatment-plans/groups/${groupId}`)
      .then((data) => {
        setTreatmentPlanGroups(
          treatmentPlanGroups.filter((group) => group.id !== groupId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectTreatmentPlanGroup = (group) => {
    handleGroupData(
      group,
      selectedTreatmentPlansGroups,
      setSelectedTreatmentPlansGroups,
      selectedTreatmentPlans,
      setSelectedTreatmentPlans,
      'Treatment plan group',
    );
  };

  const removeTreatmentPlan = (item) => {
    setSelectedTreatmentPlans(
      selectedTreatmentPlans?.filter(
        (selectedItem) => selectedItem.name !== item.name,
      ),
    );
  };

  const handleTreatmentExtras = (item, fieldName, e) => {
    const objIndex = selectedTreatmentPlans.findIndex(
      (treatmentPlan) => treatmentPlan.name == item.name,
    );
    selectedTreatmentPlans[objIndex][fieldName] = e.target.value;
    setSelectedTreatmentPlans([...selectedTreatmentPlans]);
  };

  useEffect(() => {
    getTreatmentPlan();
  }, [currentPage]);

  const selectedTreatmentPlanList = () => {
    return selectedTreatmentPlans?.map((item, index) => {
      return (
        <Row className="selected-item-row" key={index}>
          <Col className="name" lg="3" md="3" sm="12" xs="12">
            {item.name}
          </Col>
          <Col lg="3" md="3" sm="12" xs="12" className="responsive">
            <Form.Control
              type="date"
              size="sm"
              placeholder="Enter duration"
              defaultValue={item.date}
              onChange={(e) => handleTreatmentExtras(item, 'date', e)}
            />
          </Col>
          <Col lg="5" md="5" sm="12" xs="12" className="responsive">
            <Form.Control
              size="sm"
              type="text"
              defaultValue={item.note}
              placeholder="Enter note"
              onChange={(e) => handleTreatmentExtras(item, 'note', e)}
            />
          </Col>
          <Col lg="1" md="1" sm="1" xs="2" className="text-right">
            <i
              className="fa fa-times-circle pt-1 cursor-pointer"
              aria-hidden="true"
              onClick={() => removeTreatmentPlan(item)}
            ></i>
          </Col>
        </Row>
      );
    });
  };

  const allTreatmentPlan = () => {
    return treatmentPlans?.map((item, index) => {
      let isSelected = selectedTreatmentPlans.some(
        (data) => data.name === item.name,
      );
      isSelected = isSelected ? true : false;

      return (
        <ItemWithDeleteIcon
          key={index}
          item={item}
          isSelected={isSelected}
          itemClickAction={selectTreatmentPlan}
          removeClickAction={deleteTreatmentPlan}
        />
      );
    });
  };

  let groupWithMinusBtn = treatmentPlanGroups.map((group, index) => {
    let isSelected = selectedTreatmentPlansGroups.some(
      (data) => data.id === group.id,
    );

    return (
      <GroupWithDeleteIcon
        key={index}
        item={group}
        isSelected={isSelected}
        itemClickAction={selectTreatmentPlanGroup}
        removeClickAction={deleteTreatmentPlanGroup}
      />
    );
  });

  const handleSearchOrNew = async (selectedOption) => {
    try {
      const selectedData = selectedOption;
      const isExistingTreatmentPlan = selectedTreatmentPlans.some(
        (treatmentPlan) =>
          treatmentPlan?.name?.toLowerCase() ===
          selectedData?.name?.toLowerCase(),
      );
      const filteredTreatmentPlans = isExistingTreatmentPlan
        ? selectedTreatmentPlans.filter(
            (item) =>
              item?.name?.toLowerCase() !== selectedData?.name?.toLowerCase(),
          )
        : [
            ...selectedTreatmentPlans,
            {
              name: selectedData.name,
              date: '',
              note: '',
            },
          ];
      setSelectedTreatmentPlans(filteredTreatmentPlans);
      if (selectedData?.id === 'notFound') {
        try {
          const data = await postRequest('treatment-plans', {
            name: selectedData.name,
          });
          setTreatmentPlans([...treatmentPlans, data]);
          setSearchItemName('');
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnInputChange = (searchKey) => {
    setIsLoading(true);
    if (searchKey) {
      setSearchItemName(searchKey);
      setTreatmentPlansInSearch([]);
      const url = `treatment-plans?name=${encodeURIComponent(searchKey)}`;
      setSearchQuery(searchKey);

      getRequest(url)
        .then((data) => {
          const customizedResults =
            data?.data?.length > 0
              ? data.data.map((item) => ({
                  ...item,
                  label: item.name,
                  value: item.name,
                }))
              : [{ id: 'notFound', name: searchKey }];

          setTreatmentPlansInSearch(customizedResults);
          setIsLoading(false);
        })
        .catch(console.error);
    }
  };

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    const clickedArea = e.target;
    if (clickedArea.classList.contains('customize-modal-size')) {
      setIsTreatmentPlanModal(false);
    } else if (clickedArea.classList.contains('child-modal')) {
      setIsGroupModal(false);
    }
  });

  return (
    <Modal
      show={isTreatmentPlanModal}
      size="lg"
      className="customize-modal-size"
    >
      <ModalHeader title={modalTitle} action={setIsTreatmentPlanModal} />
      <Modal.Body ref={ref}>
        <SearchArea
          handleOnInputChange={handleOnInputChange}
          handleSearchOrNew={handleSearchOrNew}
          searchQuery={searchQuery}
          searchItemName={searchItemName}
          options={treatmentPlansInSearch}
          selectedInputs={selectedTreatmentPlans}
          placeholder={'Treatment Plan'}
        />
        <Tab.Container id="left-tabs-example" defaultActiveKey="all">
          <TabNav
            action={setIsGroupModal}
            selectedItems={selectedTreatmentPlans}
          />
          <Tab.Content>
            <Tab.Pane eventKey="all" className="add-scroll">
              <Row className="complains-area mr-0 ml-0">
                {allTreatmentPlan()}
              </Row>
              <LoadMore
                currentPage={currentPage}
                totalItem={totalItem}
                perPage={perPage}
                currentPageAction={setCurrentPage}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="group" className="add-scroll">
              <Row className="complains-area mr-0 ml-0 mt-1">
                {groupWithMinusBtn}
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <TreatmentPlanGroupForm
          modalTitle={modalTitle}
          isGroupModal={isGroupModal}
          setIsGroupModal={setIsGroupModal}
          itemNames={selectedTreatmentPlans.map((item) => item.name)}
          treatmentPlanGroups={treatmentPlanGroups}
          setTreatmentPlanGroups={setTreatmentPlanGroups}
          selectedTreatmentPlansGroups={selectedTreatmentPlansGroups}
          setSelectedTreatmentPlansGroups={setSelectedTreatmentPlansGroups}
        />

        <hr className="selected-hr" />
        <div className="selected-item-title">Selected list</div>
        <div className="selected-item-area">{selectedTreatmentPlanList()}</div>
      </Modal.Body>
    </Modal>
  );
};
export default memo(TreatmentPlanModal);
