import { memo, useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { itemSettingStyle, getDragDropStyle } from '../../../../utils/helpers';
import CommonDeleteBtn from '../commonDeleteBtn';
import { Row, Col, Form } from 'react-bootstrap';
import InvestigationUnit from '../investigationUnit';
import { useGetRequest } from '../../../../hooks/useGetRequest';
import { useClickAway } from 'react-use';

const Investigation = ({
  element,
  prescriptionItems,
  setShowInvestigation,
  deletePrescriptionItem,
  selectedInvestigations,
  setSelectedInvestigations,
  isHistoryPage = false,
}) => {
  const [isEdit, setIsEdit] = useState('');
  const [units, setUnits] = useState([]);

  useEffect(() => {
    getUnits();
  }, []);

  const { isLoading: isUnitLoading, refetch: getUnits } = useGetRequest(
    'getUnit',
    `units`,
    (data) => {
      setUnits(data.data);
    },
    (e) => {
      console.log(e);
    },
  );

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(
      selectedInvestigations,
      result.source.index,
      result.destination.index,
    );
    setSelectedInvestigations(reorderedItems);
  };

  // this function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleInvestigationExtras = (item, fieldName, e) => {
    const objIndex = selectedInvestigations.findIndex(
      (investigation) => investigation.name == item.name,
    );
    selectedInvestigations[objIndex][fieldName] = e.target.value;
    setSelectedInvestigations([...selectedInvestigations]);
  };

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    setIsEdit('');
  });

  const editMode = (item) => {
    return (
      <Row ref={ref} className="selected-item-row" key={item.name}>
        <Col md={4}>{item.name}</Col>
        <Col className="pr-0">
          <Form.Control
            size="sm"
            min={0}
            type="text"
            defaultValue={item.instruction}
            placeholder="Add instruction"
            onChange={(e) => handleInvestigationExtras(item, 'instruction', e)}
          />
        </Col>
        <Col className="pr-0">
          <Form.Control
            size="sm"
            type="text"
            defaultValue={item.result}
            placeholder="Add result"
            onChange={(e) => handleInvestigationExtras(item, 'result', e)}
          />
        </Col>
        <Col md={2} className="inv-parent pr-0">
          <InvestigationUnit
            index={item.name}
            units={units}
            item={item}
            selectedInvestigations={selectedInvestigations}
            action={setSelectedInvestigations}
          />
        </Col>
        <Col md="1" className="text-right pr-0">
          <i
            className="fa fa-times-circle pt-1"
            aria-hidden="true"
            onClick={() => setIsEdit('')}
          ></i>
        </Col>
      </Row>
    );
  };

  return (
    <div
      className={`row item-row ${
        selectedInvestigations.length ? 'data-item' : 'no-data-item'
      }`}
      key={`investigations`}
    >
      <span
        className="prescription-item"
        {...(!isHistoryPage
          ? {
              onClick: (e) => {
                e.stopPropagation();
                setShowInvestigation(true);
              },
            }
          : {})}
      >
        {element?.alterName?.length ? (
          <span>{element?.alterName} +</span>
        ) : (
          <span>Investigations +</span>
        )}
      </span>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <ul
              className="sub-child-list drag-drop"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {selectedInvestigations.map((item, index) => (
                <Draggable
                  key={item.name}
                  draggableId={item.name}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <>
                      <li
                        key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getDragDropStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                        onClick={() => setIsEdit(index)}
                      >
                        <div>
                          <span
                            className="text-left"
                            style={itemSettingStyle(
                              prescriptionItems?.items?.investigation
                                ?.itemStyle || {},
                            )}
                          >
                            {item.name}{' '}
                            {item?.result && (
                              <span className="pl-2">
                                -{' '}
                                <i>
                                  {item.result} {item?.unit}
                                </i>
                              </span>
                            )}
                          </span>
                          {!isHistoryPage && (
                            <CommonDeleteBtn
                              action={deletePrescriptionItem}
                              itemName={'investigation'}
                              delId={item.name}
                            />
                          )}
                        </div>
                        {item?.instruction ? (
                          <div
                            className="pl-2"
                            style={itemSettingStyle(
                              prescriptionItems?.items?.investigation
                                ?.subItemStyle || {},
                            )}
                          >
                            - {item.instruction}
                          </div>
                        ) : (
                          ''
                        )}
                      </li>
                      {index === isEdit && editMode(item)}
                    </>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default memo(Investigation);
