import React, { memo, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { Tab, Card } from 'react-bootstrap';
import TabNavMedicineDefault from '../partials/tabNavMedicineDefault';
import Suggestions from '../partials/suggestions';
import SimilarMedicine from '../partials/rx/similarMedicine';
import FormatsAndType from '../partials/rx/formatsAndType';
import Information from '../partials/rx/information';
import { MEDICINE_TYPES_SHORTS, getMedicineIcon } from '../../../utils/helpers';
import { useGetRequest } from '../../../hooks/useGetRequest';

const MedicineDefaultModalNew = ({
  item,
  removeSchedule,
  duplicateSchedule,
  isTabCabType,
  handleDefaultData,
  setIsMedicineDefault,
  handleSelectedMedicine,
}) => {
  const [indications, setIndications] = useState(null);

  useEffect(() => {
    getMedicineIndication();
  }, [item?.id]);

  const { isLoading: isLoading, refetch: getMedicineIndication } =
    useGetRequest(
      `getMedicineIndication-${item?.id}`,
      `medicine-indications/${item?.medicineIndicationId}`,
      (data) => {
        const banglaResult = Object.keys(data)
          .filter((key) => key.endsWith('Bn') && data[key].details !== '')
          .map((key) => data[key])
          .sort((a, b) => a.sort - b.sort);

        const englishResult = Object.keys(data)
          .filter((key) => !key.endsWith('Bn') && data[key].details !== '')
          .map((key) => data[key])
          .sort((a, b) => a.sort - b.sort);
        const result = { banglaResult, englishResult };
        setIndications({ ...result });
      },
      (e) => {
        console.log(e);
      },
    );

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    setIsMedicineDefault(false);
  });

  return (
    <Card className="medicine-default" ref={ref}>
      <Card.Header>
        <div className="medicine-name-and-icon">
          <img
            src={getMedicineIcon(MEDICINE_TYPES_SHORTS[item.type])}
            alt="Tablet"
            className="pr-2"
            width={40}
            height={36}
          />
          <span className="medicine-name">
            {MEDICINE_TYPES_SHORTS[item.type]} {item?.name}
          </span>
          <span>
            <i
              onClick={() => setIsMedicineDefault(false)}
              className="fa fa-times-circle"
              // style={{display: 'inline-block', float: 'right'}}
              aria-hidden="true"
            ></i>
            </span>
        </div>
        <div className="generic-name">{item?.genericName}</div>
      </Card.Header>
      <Card.Body>
        <Tab.Container id="left-tabs" defaultActiveKey="suggestions">
          <TabNavMedicineDefault />
          <Tab.Content>
            <Tab.Pane eventKey="suggestions" className="add-scroll">
              <Suggestions
                medicineId={item?.id}
                isTabCabType={isTabCabType}
                handleDefaultData={handleDefaultData}
              />
            </Tab.Pane>
            <Tab.Pane
              eventKey="similarMedicines"
              className="add-scroll similar-medicine"
            >
              <SimilarMedicine
                item={item}
                handleSelectedMedicine={handleSelectedMedicine}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="formatsAndType" className="formats-type">
              <FormatsAndType
                item={item}
                isTabCabType={isTabCabType}
                removeSchedule={removeSchedule}
                duplicateSchedule={duplicateSchedule}
                handleSelectedMedicine={handleSelectedMedicine}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="information" className="add-scroll information">
              <Information indications={indications} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card.Body>
      <Card.Footer>
        Manufacturer: <span>{item?.companyName}</span>
      </Card.Footer>
    </Card>
  );
};

export default memo(MedicineDefaultModalNew);
