import React, { useState, useEffect, memo, useRef } from 'react';
import { Modal, Row, Col, Form, Tab } from 'react-bootstrap';
import {
  getRequest,
  postRequest,
  deleteRequest,
} from '../../../utils/axiosRequests';
import { useGetRequest } from '../../../hooks/useGetRequest';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import GroupWithDeleteIcon from '../partials/groupWithDeleteIcon';
import ChiefComplainGroupForm from '../modals/chiefComplainGroupForm';
import LoadMore from '../partials/loadMore';
import ModalHeader from '../partials/modalHeader';
import SearchArea from '../partials/searchArea';
import TabNav from '../partials/tabNav';
import { useClickAway } from 'react-use';
import { handleGroupData } from '../../../utils/helpers';

const ChiefComplainModal = ({
  modalTitle,
  selectedChiefComplains,
  setSelectedChiefComplains,
  selectedComplainGroups,
  setSelectedComplainGroups,
  isComplainModal,
  setIsComplainModal,
}) => {
  const [chiefComplains, setChiefComplains] = useState([]);
  const [chiefComplainsInSearch, setChiefComplainsInSearch] = useState([]);
  const [complainGroups, setComplainGroups] = useState([]);
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [searchItemName, setSearchItemName] = useState('');
  const perPage = 25;

  useEffect(() => {
    getRequest('chief-complains/groups')
      .then((data) => {
        setComplainGroups(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const { isLoading: isChipComplainLoading, refetch: getChipComplain } =
    useGetRequest(
      'getChipComplain',
      `chief-complains?page=${currentPage}&perPage=${perPage}`,
      (data) => {
        if (currentPage > 1) {
          setChiefComplains([...chiefComplains, ...data.data]);
        } else {
          setChiefComplains(data.data);
          setTotalItem(data.total);
        }
      },
      (e) => {
        console.log(e);
      },
    );

  const selectChiefComplain = (item) => {
    const isItemExist = selectedChiefComplains?.some(
      (complain) => complain.name === item.name,
    );
    const updatedComplains = isItemExist
      ? selectedChiefComplains?.filter(
          (complain) => complain.name !== item.name,
        )
      : [
          ...selectedChiefComplains,
          { name: item.name, duration: '', unit: 'day(s)', note: '' },
        ];
    setSelectedChiefComplains(updatedComplains);
  };

  const deleteChiefComplain = (complainId) => {
    deleteRequest(`chief-complains/${complainId}`)
      .then((data) => {
        setChiefComplains(
          chiefComplains?.filter((complain) => complain.id !== complainId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteChiefComplainGroup = (groupId) => {
    deleteRequest(`chief-complains/groups/${groupId}`)
      .then((data) => {
        setComplainGroups(
          complainGroups.filter((group) => group.id !== groupId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectComplainGroup = (group) => {
    handleGroupData(
      group,
      selectedComplainGroups,
      setSelectedComplainGroups,
      selectedChiefComplains,
      setSelectedChiefComplains,
      'Chief complain group',
    );
  };

  const removeChiefComplain = (item) => {
    setSelectedChiefComplains(
      selectedChiefComplains?.filter(
        (selectedItem) => selectedItem.name !== item.name,
      ),
    );
  };

  const handleChiefComplainExtras = (item, fieldName, e) => {
    const objIndex = selectedChiefComplains.findIndex(
      (complain) => complain.name == item.name,
    );
    selectedChiefComplains[objIndex][fieldName] = e.target.value;
    setSelectedChiefComplains([...selectedChiefComplains]);
  };

  useEffect(() => {
    getChipComplain();
  }, [currentPage]);

  const selectedComplainList = () => {
    return selectedChiefComplains?.map((item, index) => {
      return (
        <Row className="selected-item-row" key={index}>
          <Col className="name" lg="3" md="3" sm="12" xs="12">
            {item.name} <span className="pl-2">for</span>
          </Col>
          <Col lg="5" md="5" sm="12" xs="12">
            <Row>
              <Col lg="7" md="7" sm="6" xs="6" className="pr-0 responsive">
                <Form.Control
                  size="sm"
                  defaultValue={item.duration}
                  min={0}
                  type="number"
                  placeholder="Enter duration"
                  onChange={(e) =>
                    handleChiefComplainExtras(item, 'duration', e)
                  }
                />
              </Col>
              <Col lg="5" md="5" sm="6" xs="6" className="responsive">
                <Form.Select
                  className="form-control form-control-sm"
                  defaultValue={item.unit}
                  onChange={(e) => handleChiefComplainExtras(item, 'unit', e)}
                >
                  <option value={'hour(s)'}>Hour(s)</option>
                  <option value={'day(s)'}>Day(s)</option>
                  <option value={'week(s)'}>Week(s)</option>
                  <option value={'month(s)'}>Month(s)</option>
                  <option value={'year(s)'}>Year(s)</option>
                  <option value={'long time'}>Long Time</option>
                </Form.Select>
              </Col>
            </Row>
          </Col>
          <Col lg="4" md="4" sm="12" xs="12" className="responsive">
            <Row>
              <Col md="10" xs="10">
                <Form.Control
                  size="sm"
                  type="text"
                  defaultValue={item.note}
                  placeholder="Enter note"
                  onChange={(e) => handleChiefComplainExtras(item, 'note', e)}
                />
              </Col>
              <Col lg="1" md="1" sm="1" xs="2">
                <i
                  className="fa fa-times-circle pt-2 cursor-pointer"
                  aria-hidden="true"
                  onClick={() => removeChiefComplain(item)}
                ></i>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    });
  };

  let allChiefComplains = chiefComplains?.map((item, index) => {
    let isSelected = selectedChiefComplains.some(
      (data) => data.name === item.name,
    );
    isSelected = isSelected ? true : false;

    return (
      <ItemWithDeleteIcon
        key={index}
        item={item}
        isSelected={isSelected}
        itemClickAction={selectChiefComplain}
        removeClickAction={deleteChiefComplain}
      />
    );
  });

  let groupWithMinusBtn = complainGroups.map((group, index) => {
    let isSelected = selectedComplainGroups.some(
      (data) => data.id === group.id,
    );

    return (
      <GroupWithDeleteIcon
        key={index}
        item={group}
        isSelected={isSelected}
        itemClickAction={selectComplainGroup}
        removeClickAction={deleteChiefComplainGroup}
      />
    );
  });

  const handleSearchOrNew = async (selectedOption) => {
    try {
      const selectedData = selectedOption;
      const isExistingComplain = selectedChiefComplains.some(
        (complain) =>
          complain?.name?.toLowerCase() === selectedData?.name?.toLowerCase(),
      );
      const filteredComplains = isExistingComplain
        ? selectedChiefComplains.filter(
            (item) =>
              item?.name?.toLowerCase() !== selectedData?.name?.toLowerCase(),
          )
        : [
            ...selectedChiefComplains,
            {
              name: selectedData.name,
              duration: '',
              unit: 'day(s)',
              note: '',
            },
          ];
      setSelectedChiefComplains(filteredComplains);
      if (selectedData?.id === 'notFound') {
        const data = await postRequest('chief-complains', {
          name: selectedData.name,
        });
        setChiefComplains([...chiefComplains, data]);
        setSearchItemName('');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnInputChange = (searchKey) => {
    setIsLoading(true);
    if (searchKey) {
      setSearchItemName(searchKey);
      setChiefComplainsInSearch([]);
      const url = `chief-complains?name=${encodeURIComponent(searchKey)}`;
      setSearchQuery(searchKey);

      getRequest(url)
        .then((data) => {
          const customizedResults =
            data?.data?.length > 0
              ? data.data.map((item) => ({
                  ...item,
                  label: item.name,
                  value: item.name,
                }))
              : [{ id: 'notFound', name: searchKey }];

          setChiefComplainsInSearch(customizedResults);
          setIsLoading(false);
        })
        .catch(console.error);
    }
  };

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    const clickedArea = e.target;
    if (clickedArea.classList.contains('customize-modal-size')) {
      setIsComplainModal(false);
    } else if (clickedArea.classList.contains('child-modal')) {
      setIsGroupModal(false);
    }
  });

  return (
    <Modal show={isComplainModal} size="lg" className="customize-modal-size">
      <ModalHeader title={modalTitle} action={setIsComplainModal} />
      <Modal.Body ref={ref}>
        <SearchArea
          handleOnInputChange={handleOnInputChange}
          handleSearchOrNew={handleSearchOrNew}
          searchQuery={searchQuery}
          searchItemName={searchItemName}
          options={chiefComplainsInSearch}
          selectedInputs={selectedChiefComplains}
          placeholder={'chief complain'}
        />
        <Tab.Container id="left-tabs-example" defaultActiveKey="all">
          <TabNav
            action={setIsGroupModal}
            selectedItems={selectedChiefComplains}
          />
          <Tab.Content>
            <Tab.Pane eventKey="all" className="add-scroll">
              <Row className="complains-area mr-0 ml-0">
                {allChiefComplains}
              </Row>
              <LoadMore
                currentPage={currentPage}
                totalItem={totalItem}
                perPage={perPage}
                currentPageAction={setCurrentPage}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="group" className="add-scroll">
              <Row className="complains-area mr-0 ml-0 mt-1">
                {groupWithMinusBtn}
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <ChiefComplainGroupForm
          modalTitle={modalTitle}
          isGroupModal={isGroupModal}
          setIsGroupModal={setIsGroupModal}
          itemNames={selectedChiefComplains.map((item) => item.name)}
          complainGroups={complainGroups}
          setComplainGroups={setComplainGroups}
          selectedComplainGroups={selectedComplainGroups}
          setSelectedComplainGroups={setSelectedComplainGroups}
        />

        <hr className="selected-hr" />
        <div className="selected-item-title">Selected list</div>
        <div className="selected-item-area">{selectedComplainList()}</div>
      </Modal.Body>
    </Modal>
  );
};
export default memo(ChiefComplainModal);
