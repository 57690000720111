import React from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';

const SelectedMedicalHistories = ({ selectedData, setSelectedHistories }) => {
  const { medical } = selectedData;
  const testData = (data) => {};

  const handleMedicalHistory = (index, fieldName, val) => {
    if (fieldName === 'present' || fieldName === 'absent') {
      selectedData.medical[index]['present'] = '';
      selectedData.medical[index]['absent'] = '';
    }
    selectedData.medical[index][fieldName] = val;
    setSelectedHistories({ ...selectedData });
  };

  const clearHistoryInput = (index) => {
    selectedData.medical[index].present = '';
    selectedData.medical[index].absent = '';
    setSelectedHistories({ ...selectedData });
  };

  const removeMedicalHistory = (item) => {
    setSelectedHistories({
      ...selectedData,
      medical: selectedData?.medical.filter((data) => data.name !== item.name),
    });
  };

  return (
    <>
      {medical?.map((item, index) => {
        return (
          <Row className="selected-item-row" key={index}>
            <Col md={2} className="font-weight-bold pr-0 pl-1">
              {item.name}
            </Col>
            <Col md={3}>
              <div className="input-group">
                <Form.Check
                  type={`radio`}
                  id={`present-${index}`}
                  className="mr-2"
                  // name="present"
                  label={`Present`}
                  checked={selectedData?.medical?.[index]?.present === 'yes'}
                  onChange={(e) =>
                    handleMedicalHistory(index, 'present', 'yes')
                  }
                />
                <Form.Check
                  type={`radio`}
                  id={`absent-${index}`}
                  // name="absent"
                  label={`Absent`}
                  checked={selectedData?.medical?.[index]?.absent === 'no'}
                  onChange={(e) => handleMedicalHistory(index, 'absent', 'no')}
                />
              </div>
            </Col>
            <Col md={1} className="pl-0">
              <Button
                size="sm"
                variant={
                  selectedData?.medical?.[index]?.present ||
                  selectedData?.medical?.[index]?.absent
                    ? 'primary'
                    : 'light'
                }
                onClick={() => clearHistoryInput(index)}
              >
                Clear
              </Button>
              {/* <span className="ml-4">For</span> */}
            </Col>
            <Col md={3} className="pl-0">
              <div className="input-group">
                <Form.Control
                  size="sm"
                  type="number"
                  min="0"
                  placeholder="Duration"
                  value={selectedData.medical?.[index]?.duration}
                  defaultValue={selectedData.medical?.[index]?.duration}
                  onChange={(e) =>
                    handleMedicalHistory(index, 'duration', e.target.value)
                  }
                />
                <select
                  className="form-control form-control-sm"
                  value={selectedData.medical?.[index]?.unit}
                  defaultValue={selectedData.medical?.DM?.unit}
                  onChange={(e) =>
                    handleMedicalHistory(index, 'unit', e.target.value)
                  }
                >
                  <option value={'day(s)'}>Day(s)</option>
                  <option value={'week(s)'}>Week(s)</option>
                  <option value={'month(s)'}>Month(s)</option>
                  <option value={'year(s)'}>Year(s)</option>
                </select>
              </div>
            </Col>
            <Col md={2} className="pl-0 pr-0">
              <Form.Control
                size="sm"
                className="w-60"
                as="textarea"
                rows={1}
                defaultValue={selectedData.medical?.[index]?.note}
                placeholder="Add note"
                onChange={(e) =>
                  handleMedicalHistory(index, 'note', e.target.value)
                }
              />
            </Col>
            <Col className="text-center">
              <i
                className="fa fa-times-circle pt-2 cursor-pointer"
                aria-hidden="true"
                onClick={() => removeMedicalHistory(item)}
              ></i>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default SelectedMedicalHistories;
