import { useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteConfirmation } from '../../../utils/helpers';
import { memo } from 'react';

const ItemMedicineWithDeleteIcon = ({
  item,
  isSelected,
  removeClickAction,
  removeMedicine,
  itemClickAction,
  showMedicineName,
}) => {
  const userInfo = useSelector((state) => state.authReducer.data);
  const [hoverClass, setHoverClass] = useState('complain-remove-btn');

  return (
    <>
      {item.createdBy === userInfo.id ? (
        <div
          className={`chief-complain-item mp-20 ${
            isSelected ? 'is-selected' : ''
          }`}
          key={item.id}
          onMouseOver={() => {
            setHoverClass('complain-remove-btn-hover');
          }}
          onMouseOut={() => {
            setHoverClass('complain-remove-btn');
          }}
        >
          <span
            className="cursor-pointer"
            onClick={() =>
              isSelected ? removeMedicine(item) : itemClickAction(item)
            }
          >
            <span style={{ fontWeight: 600 }}>
              {showMedicineName === 'brandName' || showMedicineName === 'any'
                ? item.brandName
                : item.genericName}
            </span>{' '}
            -
            <span style={{ fontSize: '12px', color: '#1f932f' }}>
              {' '}
              {item.type}{' '}
            </span>
            {item.strength ? (
              <span style={{ color: '#bf620b', fontSize: '14px' }}>
                - {item.strength}
              </span>
            ) : (
              ''
            )}
          </span>
        </div>
      ) : (
        <div
          className={`chief-complain-item mp-20 ${
            isSelected ? 'is-selected' : ''
          }`}
          key={item.id}
          onClick={() =>
            isSelected ? removeMedicine(item) : itemClickAction(item)
          }
          onMouseOut={() => {
            setHoverClass('complain-remove-btn');
          }}
        >
          <span className="cursor-pointer">
            <span style={{ fontWeight: 600 }}>
              {showMedicineName === 'brandName' || showMedicineName === 'any'
                ? item.brandName
                : item.genericName}
            </span>{' '}
            -
            <span style={{ fontSize: '12px', color: '#1f932f' }}>
              {' '}
              {item.type}{' '}
            </span>
            {item.strength ? (
              <span style={{ color: '#bf620b', fontSize: '14px' }}>
                - {item.strength}
              </span>
            ) : (
              ''
            )}
          </span>
        </div>
      )}
    </>
  );
};

export default memo(ItemMedicineWithDeleteIcon);
