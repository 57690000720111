import React, { useState, Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';
import {
  agePrint,
  sizeConverter,
  separateDateTime,
  calculatePatientLabelPadding,
} from '../../utils/helpers';
import moment from 'moment';

const PatientSectionPdf = ({
  patientInfo,
  patientSettings,
  isPad,
  appointmentInfo,
  prescriptionSetting,
}) => {
  let { name, dob, gender, phoneNumber } = patientInfo;
  dob = separateDateTime(dob);
  const firstRow = patientSettings?.items?.filter(
    (item) => item.rowNumber === 1 && item.isHidden === false,
  );
  const secondRow = patientSettings?.items?.filter(
    (item) => item.rowNumber === 2 && item.isHidden === false,
  );
  const actualWidth = prescriptionSetting?.page?.width?.quantity;
  const totalPadding = sizeConverter(
    actualWidth,
    parseFloat(prescriptionSetting?.page?.marginLeft?.quantity) +
      parseFloat(prescriptionSetting?.page?.marginRight?.quantity),
  );

  const patientSettingStyle = {
    marginLeft:
      prescriptionSetting?.patientSetting?.marginLeft?.quantity +
      prescriptionSetting?.patientSetting?.marginLeft?.unit,
    marginRight:
      prescriptionSetting?.patientSetting?.marginRight?.quantity +
      prescriptionSetting?.patientSetting?.marginRight?.unit,
    marginTop:
      prescriptionSetting?.patientSetting?.marginTop?.quantity +
      prescriptionSetting?.patientSetting?.marginTop?.unit,
    marginBottom:
      prescriptionSetting?.patientSetting?.marginBottom?.quantity +
      prescriptionSetting?.patientSetting?.marginBottom?.unit,
    borderLeft: prescriptionSetting?.patientSetting?.borderLeft,
    borderRight: prescriptionSetting?.patientSetting?.borderRight,
    borderTop: prescriptionSetting?.patientSetting?.borderTop,
    borderBottom: prescriptionSetting?.patientSetting?.borderBottom,
  };

  const appointmentDate = (appointmentDateTime) => {
    return appointmentInfo?.appointmentDateTime !== ''
      ? moment(appointmentInfo?.appointmentDateTime).format('DD/MM/YYYY')
      : moment().format('DD/MM/YYYY');
  };

  return (
    <>
      {firstRow?.length > 0 && (
        <Row
          className={`patient-section-print ${
            isPad ? '' : 'no-pad-patient-section'
          }`}
          style={isPad ? patientSettingStyle : {}}
        >
          {firstRow?.map((item, index) => {
            return (
              <Fragment key={index}>
                {isPad ? (
                  item?.label?.properties?.isHidden ? (
                    <div
                      style={calculatePatientLabelPadding(
                        item,
                        'label',
                        'paddingLeft',
                        'paddingRight',
                        'paddingTop',
                        'paddingBottom',
                        actualWidth,
                        totalPadding,
                        isPad,
                      )}
                    ></div>
                  ) : (
                    <div
                      style={calculatePatientLabelPadding(
                        item,
                        'label',
                        'paddingLeft',
                        'paddingRight',
                        'paddingTop',
                        'paddingBottom',
                        actualWidth,
                        totalPadding,
                        isPad,
                      )}
                    >
                      {item?.label?.labelName}:
                    </div>
                  )
                ) : (
                  ''
                )}
                {isPad ? (
                  <div
                    className="p-item-val"
                    style={
                      isPad
                        ? calculatePatientLabelPadding(
                            item,
                            'value',
                            'paddingLeft',
                            'paddingRight',
                            'paddingTop',
                            'paddingBottom',
                            actualWidth,
                            totalPadding,
                            isPad,
                          )
                        : {}
                    }
                  >
                    {item.name === 'age'
                      ? agePrint(dob, item?.unitProperties)
                      : item.name === 'date'
                      ? appointmentDate()
                      : patientInfo[item.name]}
                  </div>
                ) : (
                  <div className="n">
                    {item?.label?.labelName}: &nbsp;{' '}
                    {item.name === 'age'
                      ? agePrint(dob, item?.unitProperties)
                      : item.name === 'date'
                      ? appointmentDate()
                      : patientInfo[item.name]}
                  </div>
                )}
              </Fragment>
            );
          })}
        </Row>
      )}
      {secondRow?.length > 0 && (
        <Row
          className={`patient-section-print pdf ${
            isPad ? '' : 'no-pad-patient-section'
          }`}
          style={isPad ? patientSettingStyle : {}}
        >
          {secondRow?.map((item, index) => {
            return (
              <Fragment key={index}>
                {isPad ? (
                  item?.label?.properties?.isHidden ? (
                    <div
                      style={calculatePatientLabelPadding(
                        item,
                        'label',
                        'paddingLeft',
                        'paddingRight',
                        'paddingTop',
                        'paddingBottom',
                        actualWidth,
                        totalPadding,
                        isPad,
                      )}
                    ></div>
                  ) : (
                    <div
                      style={calculatePatientLabelPadding(
                        item,
                        'label',
                        'paddingLeft',
                        'paddingRight',
                        'paddingTop',
                        'paddingBottom',
                        actualWidth,
                        totalPadding,
                        isPad,
                      )}
                    >
                      {item?.label?.labelName}:
                    </div>
                  )
                ) : (
                  ''
                )}
                {isPad ? (
                  <div
                    className="p-item-val"
                    style={
                      isPad
                        ? calculatePatientLabelPadding(
                            item,
                            'value',
                            'paddingLeft',
                            'paddingRight',
                            'paddingTop',
                            'paddingBottom',
                            actualWidth,
                            totalPadding,
                            isPad,
                          )
                        : {}
                    }
                  >
                    {item.name === 'age'
                      ? agePrint(dob, item?.unitProperties)
                      : item.name === 'date'
                      ? appointmentDate()
                      : patientInfo[item.name]}
                  </div>
                ) : (
                  <div className="n">
                    {item?.label?.labelName}: &nbsp;{' '}
                    {item.name === 'age'
                      ? agePrint(dob, item?.unitProperties)
                      : item.name === 'date'
                      ? appointmentDate()
                      : patientInfo[item.name]}
                  </div>
                )}
              </Fragment>
            );
          })}
        </Row>
      )}
    </>
  );
};

export default memo(PatientSectionPdf);
