import React, { memo, useState, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { itemSettingStyle, getDragDropStyle } from '../../../utils/helpers';
import { Row, Col, Form } from 'react-bootstrap';
import CommonDeleteBtn from './commonDeleteBtn';
import { useClickAway } from 'react-use';

const DiagnosisView = ({
  selectedDiagnosis,
  setSelectedDiagnosis,
  setShowDiagnosis,
  prescriptionItems,
  deletePrescriptionItem,
  selectedStage,
  element,
  isHistoryPage = false,
}) => {
  const { stage, isShow } = selectedStage || {};
  const [isEdit, setIsEdit] = useState('');

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(
      selectedDiagnosis,
      result.source.index,
      result.destination.index,
    );
    setSelectedDiagnosis(reorderedItems);
  };

  // this function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDiagnosesExtra = (item, fieldName, value) => {
    const objIndex = selectedDiagnosis.findIndex(
      (diagnosis) => diagnosis.name == item.name,
    );
    selectedDiagnosis[objIndex][fieldName] = value;
    setSelectedDiagnosis([...selectedDiagnosis]);
  };

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    setIsEdit('');
  });

  const editMode = (data) => {
    return (
      <Row ref={ref} className="selected-item-row" key={data?.name}>
        <Col>{data?.name}</Col>
        <Col md={2}>
          <Form.Check
            type={`checkbox`}
            id={`query`}
            label={`Query`}
            checked={data?.query}
            onChange={(evt) =>
              handleDiagnosesExtra(data, 'query', evt.target.checked)
            }
          />
        </Col>
        <Col>
          <Form.Control
            size="sm"
            type="text"
            defaultValue={data?.note}
            placeholder="Enter note"
            onChange={(evt) =>
              handleDiagnosesExtra(data, 'note', evt.target.value)
            }
          />
        </Col>
        <Col md={1} className="text-right pr-0 pl-0">
          <i
            className="fa fa-times-circle pt-1"
            aria-hidden="true"
            onClick={() => setIsEdit('')}
          ></i>
        </Col>
      </Row>
    );
  };

  return (
    <div
      className={`row item-row ${
        selectedDiagnosis.length ? 'data-item' : 'no-data-item'
      }`}
      key={selectedDiagnosis.name}
    >
      <span
        className="prescription-item"
        {...(!isHistoryPage
          ? {
              onClick: (e) => {
                e.stopPropagation();
                setShowDiagnosis(true);
              },
            }
          : {})}
      >
        {element?.alterName?.length ? (
          <span>{element?.alterName} +</span>
        ) : (
          <span>Diagnosis +</span>
        )}
      </span>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <ul
              className="sub-child-list drag-drop"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {selectedDiagnosis.length > 0 &&
                selectedDiagnosis?.map((item, index) => (
                  <Draggable
                    key={item.name}
                    draggableId={item.name}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <>
                        <li
                          key={item.name}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getDragDropStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                          onClick={() => setIsEdit(index)}
                        >
                          <div>
                            <span
                              className="text-left pr-5"
                              style={itemSettingStyle(
                                prescriptionItems?.items?.['diagnosis']
                                  ?.itemStyle || {},
                              )}
                            >
                              {item.query ? '?' : ''} {item.query} {item.name}
                            </span>
                            {!isHistoryPage && (
                              <CommonDeleteBtn
                                action={deletePrescriptionItem}
                                itemName={'diagnosis'}
                                delId={item.name}
                              />
                            )}
                            {item.note !== '' && (
                              <div
                                className="pl-2"
                                style={itemSettingStyle(
                                  prescriptionItems?.items?.['diagnosis']
                                    ?.subItemStyle || {},
                                )}
                              >
                                {item.note ? `- ${item.note}` : ''}
                              </div>
                            )}
                          </div>
                        </li>
                        {index === isEdit && editMode(item)}
                      </>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}

              {stage && isShow && (
                <li key={stage}>
                  <div
                    style={itemSettingStyle(
                      prescriptionItems?.items?.['diagnosis']?.itemStyle || {},
                    )}
                  >
                    Stage: {stage}
                  </div>
                </li>
              )}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default memo(DiagnosisView);
