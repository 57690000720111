import { useSelector } from 'react-redux';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';

const BarcodeQrCode = ({ prescriptionId, patientInfo }) => {
  const userInfo = useSelector((state) => state.authReducer.data);
  const { doctorId, organizationId } = patientInfo;
  const baseUrl = process.env.REACT_APP_BASE_URL;

  return (
    <>
      {prescriptionId && userInfo?.codeType === 'barcode' && (
        <div className="bar-qr-code">
          <Barcode
            value={`${baseUrl}/pdf?dId=${doctorId}&pId=${prescriptionId}&oId=${organizationId}`}
            displayValue={false}
            width={0.5} // Reduced width to shorten the barcode length
            height={40}
            format="CODE128"
            fontOptions=""
            font="monospace"
            textAlign="center"
            textPosition="bottom"
            textMargin={1}
            fontSize={20}
            background="#ffffff"
            lineColor="#000000"
            margin={10}
          />
        </div>
      )}
      {prescriptionId && userInfo?.codeType === 'qrcode' && (
        <div
          className="bar-qr-code"
          style={{
            height: 'auto',
            margin: '0 auto',
            maxWidth: 64,
            width: '100%',
          }}
        >
          <QRCode
            size={256}
            style={{
              height: 'auto',
              maxWidth: '100%',
              width: '100%',
            }}
            value={`${baseUrl}/pdf-prescription-by-scan?doctorId=${doctorId}&prescriptionId=${prescriptionId}&organizationId=${organizationId}`}
            viewBox={`0 0 256 256`}
          />
        </div>
      )}
    </>
  );
};

export default BarcodeQrCode;
