import React, { useState, useEffect, memo } from 'react';
import { Modal, Row, Col, Tab, Form } from 'react-bootstrap';
import {
  getRequest,
  postRequest,
  deleteRequest,
} from '../../../utils/axiosRequests';
import { useGetRequest } from '../../../hooks/useGetRequest';
import AdviceGroupForm from './adviceGroupForm';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import GroupWithDeleteIcon from '../partials/groupWithDeleteIcon';
import LoadMore from '../partials/loadMore';
import ModalHeader from '../partials/modalHeader';
import SearchArea from '../partials/searchArea';
import TabNav from '../partials/tabNav';
import { handleGroupData } from '../../../utils/helpers';

const AdviceModal = ({
  modalTitle,
  selectedAdvices,
  setSelectedAdvices,
  selectedAdvicesGroups,
  setSelectedAdvicesGroups,
  showAdvice,
  setShowAdvice,
}) => {
  const [advices, setAdvices] = useState([]);
  const [advicesInSearch, setAdvicesInSearch] = useState([]);
  const [adviceGroups, setAdviceGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAdviceGroupModal, setIsAdviceGroupModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [selectedForSub, setSelectedForSub] = useState('');
  const [searchItemName, setSearchItemName] = useState('');
  const perPage = 25;

  const { isLoading: isAdviceLoading, refetch: getAdvices } = useGetRequest(
    'getAdvice',
    `advice?page=${currentPage}&perPage=${perPage}`,
    (data) => {
      if (currentPage > 1) {
        setAdvices([...advices, ...data.data]);
      } else {
        setAdvices(data.data);
        setTotalItem(data.total);
      }
    },
    (e) => {
      console.log(e);
    },
  );

  const selectAdvice = (item) => {
    if (!selectedForSub) {
      if (selectedAdvices.some((advice) => advice.name === item.name)) {
        setSelectedAdvices(
          selectedAdvices.filter((advice) => advice.name !== item.name),
        );
      } else {
        setSelectedAdvices([...selectedAdvices, { name: item.name, note: '' }]);
      }
    } else {
      const parentAdvice = selectedAdvices.find(
        (item) => item.name === selectedForSub,
      );
      let subAdvices = parentAdvice?.subAdvices ? parentAdvice?.subAdvices : [];
      if (subAdvices.length) {
        if (subAdvices.some((subAdvice) => subAdvice.name === item.name)) {
          subAdvices = subAdvices.filter(
            (subAdvice) => subAdvice.name !== item.name,
          );
        } else {
          const { priority, ...rest } = item;
          subAdvices.push(rest);
        }
      } else {
        const { priority, ...rest } = item;
        subAdvices.push(rest);
      }
      parentAdvice.subAdvices = subAdvices;
      setSelectedAdvices([...selectedAdvices]);
    }
  };

  const handleSearchOrNew = (selectedOption) => {
    let selectedData = selectedOption;
    if (
      !selectedAdvices.some((advice) => advice.id === selectedData.id) &&
      selectedData.id !== 'notFound'
    ) {
      if (!selectedForSub) {
        setSelectedAdvices([
          ...selectedAdvices,
          { id: selectedData.id, name: selectedData.name, note: '' },
        ]);
      } else {
        const parentAdvice = selectedAdvices?.find(
          (item) => item.id === selectedForSub,
        );
        let subAdvices = parentAdvice?.subAdvices
          ? parentAdvice?.subAdvices
          : [];
        if (subAdvices.length) {
          if (
            subAdvices.some((subAdvice) => subAdvice.id === selectedData.id)
          ) {
            subAdvices = subAdvices.filter(
              (subAdvice) => subAdvice.id !== selectedData.id,
            );
          } else {
            const { priority, ...rest } = selectedData;
            subAdvices.push(rest);
          }
        } else {
          const { priority, ...rest } = selectedData;
          subAdvices.push(rest);
        }
        parentAdvice.subAdvices = subAdvices;
        setSelectedAdvices([...selectedAdvices]);
      }
    } else {
      postRequest('advice', { name: selectedData.name })
        .then((data) => {
          setSearchItemName('');
          setAdvices([...advices, data]);
          setSelectedAdvices([
            ...selectedAdvices,
            { id: data.id, name: data.name, note: '' },
          ]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setSearchQuery('');
  };

  const handleOnInputChange = (searchKey) => {
    setIsLoading(true);
    if (searchKey) {
      setSearchItemName(searchKey);
      setAdvicesInSearch([]);

      const url = `advice?name=${encodeURIComponent(searchKey)}`;
      setSearchQuery(searchKey);

      getRequest(url)
        .then((data) => {
          const customizedResults =
            data?.data?.length > 0
              ? data.data.map((item) => ({
                  ...item,
                  label: item.name,
                  value: item.name,
                }))
              : [{ id: 'notFound', name: searchKey }];

          setAdvicesInSearch(customizedResults);
          setIsLoading(false);
        })
        .catch(console.error);
    }
  };

  const generateSubAdvices = () => {
    const subs = selectedAdvices?.map((item) => {
      const subAdvs = item?.subAdvices?.map((sub) => ({
        id: sub.id,
        name: sub.name,
      }));
      return { name: item.name, id: item.id, subAdvices: subAdvs };
    });
    return subs;
  };

  const deleteAdvice = (AdviceId) => {
    deleteRequest(`advice/${AdviceId}`)
      .then((data) => {
        setAdvices(advices.filter((advice) => advice.id !== AdviceId));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteAdviceGroup = (groupId) => {
    deleteRequest(`advice/groups/${groupId}`)
      .then((data) => {
        setAdviceGroups(adviceGroups.filter((group) => group.id !== groupId));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const selectAdviceGroup = (group) => {
    handleGroupData(
      group,
      selectedAdvicesGroups,
      setSelectedAdvicesGroups,
      selectedAdvices,
      setSelectedAdvices,
      'Advice group',
    );
  };

  const removeAdvice = (itemName) => {
    const data = selectedAdvices.filter(
      (selectedItem) => selectedItem.name !== itemName,
    );
    setSelectedAdvices([...data]);
    setSelectedForSub('');
  };

  const removeSubAdvice = (item, subItemName) => {
    let subItems = item?.subAdvices;
    subItems = subItems.filter((subItem) => subItem.name !== subItemName);
    item.subAdvices = subItems;
    const advices = selectedAdvices.map((advice) => {
      if (advice.name === item.name) {
        return item;
      } else {
        return advice;
      }
    });
    setSelectedAdvices([...advices]);
  };

  const handleAdviceNote = (key, val) => {
    selectedAdvices[key].note = val;
    setSelectedAdvices(selectedAdvices);
  };

  const handleAdviceSubNote = (itemMain, parentIndex, key, val) => {
    itemMain.subAdvices[key]['note'] = val;
    selectedAdvices[parentIndex] = itemMain;
    setSelectedAdvices([...selectedAdvices]);
  };

  const handleSelectedForSub = (itemName) => {
    if (itemName === selectedForSub) {
      setSelectedForSub('');
    } else {
      setSelectedForSub(itemName);
    }
  };

  useEffect(() => {
    getRequest('advice/groups')
      .then((data) => {
        setAdviceGroups(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getAdvices();
  }, [currentPage]);

  const selectedSubAdviceList = (itemMain, parentIndex) =>
    itemMain?.subAdvices?.map((item, index) => {
      return (
        <Row
          className="selected-item-row"
          key={index}
          style={{ marginLeft: '20px', marginRight: '20px' }}
        >
          <Col lg={6} md={6} sm={6} xs={6}>{item.name}</Col>
          <Col lg={5} md={5} sm={5} xs={5}>
            <Form.Control
              size="sm"
              type="text"
              defaultValue={item.note}
              placeholder="Enter note"
              onChange={(e) =>
                handleAdviceSubNote(
                  itemMain,
                  parentIndex,
                  index,
                  e.target.value,
                )
              }
            />
          </Col>
          <Col lg={1} md={1} sm={1} xs={1} className="text-right">
            <i
              className="fa fa-times-circle"
              aria-hidden="true"
              onClick={() => removeSubAdvice(itemMain, item.name)}
            ></i>
          </Col>
        </Row>
      );
    });

  const selectedAdviceList = () => {
    return selectedAdvices.map((item, index) => {
      return (
        <>
          {item?.title && (
            <span className="advice-title" key={index}>
              {item?.title}:{' '}
            </span>
          )}
          <Row className="selected-item-row" key={index}>
            <Col lg={5} md={5} sm={5} xs={5}>
              {item.name}
            </Col>
            <Col lg={5} md={5} sm={5} xs={5}>
              <Form.Control
                size="sm"
                type="text"
                defaultValue={item.note}
                placeholder="Enter note"
                onChange={(e) => handleAdviceNote(index, e.target.value)}
              />
            </Col>
            <Col lg={1} md={1} sm={1} xs={1}>
              <span
                className="text-left"
                style={{
                  width: '70%',
                  display: 'inline-block',
                  cursor: 'pointer',
                }}
              >
                <i
                  className={`fa fa-plus ${
                    selectedForSub === item.name ? 'dark' : 'grey'
                  }`}
                  aria-hidden="true"
                  onClick={() => handleSelectedForSub(item.name)}
                ></i>
              </span>
            </Col>
            <Col lg={1} md={1} sm={1} xs={1}>
              <span
                className="text-right"
                style={{ width: '30%', display: 'inline-block' }}
              >
                <i
                  className="fa fa-times-circle"
                  aria-hidden="true"
                  onClick={() => removeAdvice(item.name)}
                ></i>
              </span>
            </Col>
          </Row>
          {selectedSubAdviceList(item, index)}
        </>
      );
    });
  };

  let allAdvices = () => {
    return advices.map((item, index) => {
      const isSelected = selectedAdvices.some((data) => {
        if (
          data.name === item.name ||
          data?.subAdvices?.some((sub) => sub.name === item.name)
        ) {
          return true;
        } else {
          return false;
        }
      });

      return (
        <ItemWithDeleteIcon
          key={item.id}
          item={item}
          isSelected={isSelected}
          itemClickAction={selectAdvice}
          removeClickAction={deleteAdvice}
          itemType={`advice`}
        />
      );
    });
  };

  const allAdviceGroup = () => {
    return adviceGroups.map((group, index) => {
      let isSelected = selectedAdvicesGroups.some(
        (data) => data.id === group.id,
      );
      return (
        <GroupWithDeleteIcon
          key={index}
          item={group}
          isSelected={isSelected}
          itemClickAction={selectAdviceGroup}
          removeClickAction={deleteAdviceGroup}
        />
      );
    });
  };

  return (
    <Modal
      show={showAdvice}
      size="lg"
      className="customize-modal-size"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ModalHeader title={modalTitle} action={setShowAdvice} />
      <Modal.Body>
        <SearchArea
          handleOnInputChange={handleOnInputChange}
          handleSearchOrNew={handleSearchOrNew}
          searchQuery={searchQuery}
          searchItemName={searchItemName}
          options={advicesInSearch}
          placeholder={'advice'}
          selectedInputs={selectedAdvices}
        />
        <Tab.Container id="left-tabs-example" defaultActiveKey="all">
          <TabNav
            action={setIsAdviceGroupModal}
            selectedItems={selectedAdvices}
          />
          <Tab.Content>
            <Tab.Pane eventKey="all" className="add-scroll">
              <Row className="complains-area mr-0 ml-0">{allAdvices()}</Row>
              <LoadMore
                currentPage={currentPage}
                totalItem={totalItem}
                perPage={perPage}
                currentPageAction={setCurrentPage}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="group" className="add-scroll">
              <Row className="complains-area mr-0 ml-0  mt-1">
                {allAdviceGroup()}
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <AdviceGroupForm
          modalTitle={modalTitle}
          itemNames={selectedAdvices.map((item) => item.name)}
          advices={selectedAdvices}
          subAdvices={generateSubAdvices()}
          adviceGroups={adviceGroups}
          setAdviceGroups={setAdviceGroups}
          selectedAdvicesGroups={selectedAdvicesGroups}
          setSelectedAdvicesGroups={setSelectedAdvicesGroups}
          isAdviceGroupModal={isAdviceGroupModal}
          setIsAdviceGroupModal={setIsAdviceGroupModal}
        />

        <hr className="selected-hr" />
        <div className="selected-item-title">Selected list</div>
        <div className="selected-item-area">{selectedAdviceList()}</div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(AdviceModal);
