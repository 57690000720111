import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Tab, Nav } from 'react-bootstrap';
import { patchRequest, postRequest } from '../../../utils/axiosRequests';
import { useGetRequest } from '../../../hooks/useGetRequest';

const Suggestion = ({ medicineId, isTabCabType, handleDefaultData }) => {
  const [schedules, setSchedules] = useState([]);
  const [doseRecommendations, setDoseRecommendations] = useState([]);
  const userInfo = useSelector((state) => state.authReducer.data);

  useEffect(() => {
    getMedicineDefault();
    getDoseRecommendation();
  }, [medicineId]);

  const { isLoading: isLoading, refetch: getMedicineDefault } = useGetRequest(
    `getMedicineDefault-${medicineId}`,
    `medicine-default?medicineId=${medicineId}`,
    (data) => {
      setSchedules([...data.data]);
    },
    (e) => {
      console.log(e);
    },
  );

  const {
    isLoading: isDoseLoading,
    refetch: getDoseRecommendation,
  } = useGetRequest(
    `getDoseRecom-${medicineId}`,
    `medicine-default/dose-recommendation/${medicineId}`,
    (data) => {
      setDoseRecommendations([...data.data]);
    },
    (e) => {
      console.log(e);
    },
  );

  const defaultAction = (
    defaultId,
    medicineId,
    doctorId,
    val,
    scheduleData,
    type,
  ) => {
    patchRequest(`medicine-default/${defaultId}`, {
      medicineId: medicineId,
      doctorId: doctorId,
      [type]: val,
      type,
    })
      .then((response) => {
        setSchedules([...response.data]);
        if (type === 'isSelected') {
          handleDefaultData(scheduleData);
        }
        toast.success('Updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const scheduleList = () => {
    return schedules?.map((parentData, key) => {
      return parentData?.itemDetails?.map((scheduleData, index) => {
        return (
          <div
            className={`schedule-instruction-row ${
              parentData?.isDefault ? 'default' : 'hover'
            } 
            ${parentData?.isSelected ? 'selected' : ''}
            `}
            key={index}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                defaultAction(
                  parentData.id,
                  parentData.medicineId,
                  parentData.doctorId,
                  parentData?.isSelected ? false : true,
                  scheduleData,
                  'isSelected',
                );
              }}
              className={`schedule-instruction`}
            >
              <span className="schedule">
                {isTabCabType &&
                  Object.values(scheduleData?.quantities)?.map(
                    (item, index) => (
                      <span key={index}>
                        {item}
                        {++index ===
                        Object.values(scheduleData?.quantities).length
                          ? ' ' + scheduleData?.quantityUnit
                          : ' + '}
                      </span>
                    ),
                  )}
                {!isTabCabType && (
                  <span>
                    {scheduleData.quantity +
                      ' ' +
                      scheduleData.quantityUnit +
                      ' ' +
                      scheduleData.schedule +
                      ' ' +
                      scheduleData.scheduleUnit}
                  </span>
                )}
              </span>
              <span className="instruction">
                {scheduleData?.instructions?.map((insItem, key) => (
                  <span key={key}>
                    {insItem}{' '}
                    {scheduleData?.instructions?.length !== key + 1 && ', '}
                  </span>
                ))}
              </span>
            </div>
            <div
              className={
                parentData.isDefault ? 'selected-default' : 'make-default-btn'
              }
              onClick={(e) => {
                e.stopPropagation();
                defaultAction(
                  parentData.id,
                  parentData.medicineId,
                  parentData.doctorId,
                  parentData.isDefault ? false : true,
                  scheduleData,
                  'isDefault',
                );
              }}
            >
              {parentData.isDefault ? 'Remove Default' : 'Make Default'}
            </div>
          </div>
        );
      });
    });
  };

  const doseRecommendToDefault = (inputData) => {
    inputData.isDefault = true;
    console.log(inputData, 'inputData');
    postRequest(`medicine-default`, inputData)
      .then((response) => {
        toast.success('Updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        getMedicineDefault();
      })
      .catch((error) => {
        console.log(error);
        toast.error('Something went wrong', {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const doseRecommendationList = () => {
    return doseRecommendations
      ?.filter((data) => data.doctorId !== userInfo?.id)
      .map((parentDoseData, key) => {
        return parentDoseData?.itemDetails?.map((doseData, index) => {
          return (
            <div className={`schedule-instruction-row`} key={index}>
              <div
                onClick={() => handleDefaultData(doseData)}
                className={`schedule-instruction`}
              >
                <span className="schedule">
                  {isTabCabType &&
                    Object.values(doseData?.quantities)?.map((item, index) => (
                      <span key={index}>
                        {item}
                        {++index === Object.values(doseData?.quantities).length
                          ? ' ' + doseData?.quantityUnit
                          : ' + '}
                      </span>
                    ))}
                  {!isTabCabType && (
                    <span>
                      {doseData.quantity +
                        ' ' +
                        doseData.quantityUnit +
                        ' ' +
                        doseData.schedule +
                        ' ' +
                        doseData.scheduleUnit}
                    </span>
                  )}
                </span>
                <span className="instruction">
                  {doseData?.instructions?.map((insItem, key) => (
                    <span key={key}>
                      {insItem}{' '}
                      {doseData?.instructions?.length !== key + 1 && ', '}
                    </span>
                  ))}
                </span>
              </div>
              <div
                className={'make-default-btn'}
                onClick={() => doseRecommendToDefault(parentDoseData)}
              >
                Make Default
              </div>
            </div>
          );
        });
      });
  };

  return (
    <Tab.Container defaultActiveKey="user-suggestion">
      <Nav variant="pills" className="mt-2 medicine-suggestion">
        <Nav.Item>
          <Nav.Link eventKey="user-suggestion">User Suggestion</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="dose-recommendation">
            Dose Recommendation
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey="user-suggestion" className="user-suggestion">
          {schedules?.length > 0 && scheduleList()}
        </Tab.Pane>
        <Tab.Pane
          eventKey="dose-recommendation"
          className="dose-recommendation"
        >
          {doseRecommendations?.length > 0 && doseRecommendationList()}
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

export default memo(Suggestion);
