import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  pagePrintStyle,
  leftOrRightItems,
  isFemaleSelected,
  isExistAnyHistory,
  isExistAnyEyeGlass,
  leftSidePrintStyle,
  isExistAnyRehabData,
  rightSidePrintStyle,
  ageConvertToDateTime,
  getPatientIdForDoctor,
  isExistAnyOnExamination,
  formatOldPrescriptionData,
  isExistAnyInfertilityData,
  handleGroupData,
} from '../../utils/helpers';
import '../../print.css';
import { useReactToPrint } from 'react-to-print';
import { useGetRequest } from '../../hooks/useGetRequest';
import {
  getRequest,
  patchRequest,
  postRequest,
} from '../../utils/axiosRequests';
import { onExaminationData } from '../../pages/doctor/onExaminationData';
import PrescriptionTop from '../../components/doctors/prescriptionTop';
import PrescriptionHeader from '../../components/doctors/prescriptionHeader';
import PrescriptionFooter from '../../components/doctors/prescriptionFooter';
import PatientSection from '../../components/doctors/patientSection';
import ChiefComplainModal from '../../components/doctors/modals/chiefComplain';
import ChiefComplainView from '../../components/doctors/partials/views/chiefComplain';
import HistoryModal from '../../components/doctors/modals/history';
import DiagnosisModal from '../../components/doctors/modals/diagnosis';
import OnExaminationModal from '../../components/doctors/modals/onExamination';
import AdviceModal from '../../components/doctors/modals/advice';
import SpecialNoteModal from '../../components/doctors/modals/specialNote';
import FollowUpModal from '../../components/doctors/modals/followUp';
import RxModal from '../../components/doctors/modals/rx';
import InvestigationModal from '../../components/doctors/modals/investigation';
import EyeGlassModal from '../../components/doctors/modals/eyeGlass';
import Header from '../../components/doctors/partials/Header';
import PrescriptionSaveBtns from '../../components/doctors/partials/prescriptionSaveBtns';
import RxView from '../../components/doctors/partials/rxView';
import DiagnosisView from '../../components/doctors/partials/diagnosisView';
import EyeGlassView from '../../components/doctors/partials/views/eyeGlassView';
import HistoryView from '../../components/doctors/partials/views/historyView';
import TemplateModal from '../../components/doctors/modals/templateModal';
import RehabilitationView from '../../components/doctors/partials/views/rehabilitationView';
import RehabilitationModal from '../../components/doctors/modals/rehabilitation';
import VideoCallModal from '../../components/doctors/modals/videoCallModal';
import InfertilityModal from '../../components/doctors/modals/infertility';
import InfertilityView from '../../components/doctors/partials/views/infertility';
import InvestigationView from '../../components/doctors/partials/views/investigation';
import AdviceView from '../../components/doctors/partials/views/advice';
import FollowUpView from '../../components/doctors/partials/views/followUp';
import SpecialNoteView from '../../components/doctors/partials/views/specialNoteView';
import SignatureView from '../../components/doctors/partials/views/signatureView';
import OnExaminationView from '../../components/doctors/partials/views/onExamination';
import PredictionsView from '../../components/doctors/partials/views/predictions';
import loadingImg from '../../img/saved.gif';
import { RxContext } from '../../contexts/RxContext';
import { DiagnosisContext } from '../../contexts/DiagnosisContext';
import { PrescriptionTopContext } from '../../contexts/PrescriptionTopContext';
import { io } from 'socket.io-client';
import PredictionsModal from '../../components/doctors/modals/predictions';
import { HistoryContext } from '../../contexts/HistoryContext';
import BarcodeQrCode from '../../components/doctors/partials/views/barcodeQrCode';
import TreatmentPlanModal from '../../components/doctors/modals/treatmentPlan';
import TreatmentPlanView from '../../components/doctors/partials/views/treatmentPlan';

function Prescription() {
  const printRef = useRef(null);
  const userInfo = useSelector((state) => state.authReducer.data);
  const activeOrganization = useSelector(
    (state) => state.orgReducer.organization,
  );
  const header = useSelector(
    (state) => state?.prescriptionReducer?.prescriptionInfo?.header,
  );
  const footer = useSelector(
    (state) => state?.prescriptionReducer?.prescriptionInfo?.footer,
  );
  const prescriptionSetting = useSelector(
    (state) =>
      state?.prescriptionReducer?.prescriptionInfo?.prescriptionSettings,
  );
  const isPad = prescriptionSetting?.isPadPrescription;
  const prescriptionItems = useSelector(
    (state) => state?.prescriptionReducer?.prescriptionInfo?.prescriptionItems,
  );
  const [printStatus, setPrintStatus] = useState(false);
  const location = useLocation();
  const [isComplainModal, setIsComplainModal] = useState(false);
  const [isTreatmentPlanModal, setIsTreatmentPlanModal] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [note, setNote] = useState('');
  const [isTemplateModal, setIsTemplateModal] = useState(false);
  const [showOnExamination, setShowOnExamination] = useState(false);
  const [showPredictions, setShowPredictions] = useState(false);
  const [showDiagnosis, setShowDiagnosis] = useState(false);
  const [showInvestigation, setShowInvestigation] = useState(false);
  const [showAdvice, setShowAdvice] = useState(false);
  const [showSpecialNote, setShowSpecialNote] = useState(false);
  const [showInfertility, setShowInfertility] = useState(false);
  const [showRehabilitation, setShowRehabilitation] = useState(false);
  const [showEyeGlass, setShowEyeGlass] = useState(false);
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [showRx, setShowRx] = useState(false);
  const [instructions, setInstructions] = useState([]);
  const [selectedChiefComplains, setSelectedChiefComplains] = useState([]);
  const [selectedComplainGroups, setSelectedComplainGroups] = useState([]);
  const [selectedAdvices, setSelectedAdvices] = useState([]);
  const [selectedSpecialNotes, setSelectedSpecialNotes] = useState([]);
  const [selectedAdvicesGroups, setSelectedAdvicesGroups] = useState([]);
  const [selectedInfertilities, setSelectedInfertilities] = useState({});
  const [selectedTreatmentPlans, setSelectedTreatmentPlans] = useState([]);
  const [selectedTreatmentPlansGroups, setSelectedTreatmentPlansGroups] =
    useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedHistoryTab, setSelectedHistoryTab] = useState('medical');
  const [selectedDiagnosisTab, setSelectedDiagnosisTab] = useState('all');
  const [selectedOnExaminationTab, setSelectedOnExaminationTab] =
    useState('observation');
  const [selectedInfertilityWomenGroups, setSelectedInfertilityWomenGroups] =
    useState([]);
  const [selectedInfertilityManGroups, setSelectedInfertilityManGroups] =
    useState([]);
  const [selectedRehabilitation, setSelectedRehabilitation] = useState({
    orthoses: [],
    exercises: [],
    physicalTherapies: [],
  });
  const [selectedFollowUps, setSelectedFollowUps] = useState([]);
  const [selectedEyeGlass, setSelectedEyeGlass] = useState({
    isPlano: false,
    leftPlano: false,
    rightPlano: false,
    r: { sph: '', cyl: '', axis: '' },
    l: { sph: '', cyl: '', axis: '' },
    remarks: [],
  });
  const [selectedDiagnosisGroups, setSelectedDiagnosisGroups] = useState([]);
  const [selectedInvestigations, setSelectedInvestigations] = useState([]);
  const [selectedInvestigationGroups, setSelectedInvestigationGroups] =
    useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
  const [selectedStage, setSelectedStage] = useState({});
  const [selectedHistories, setSelectedHistories] = useState({});
  const [selectedOnExamination, setSelectedOnExamination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [appointmentStatus, setAppointmentStatus] = useState(false);
  const [editMedicineId, setEditMedicineId] = useState('');
  ///////////////////Medicine related/////////////////////////
  const perPage = 25;
  const [totalItem, setTotalItem] = useState(0);
  const [medicines, setMedicines] = useState([]);
  const [medicinesInSearch, setMedicinesInSearch] = useState([]);
  const [selectedMedicines, setSelectedMedicines] = useState([]);
  const [selectedMedicineGroups, setSelectedMedicineGroups] = useState([]);
  const [medicineGroups, setMedicineGroups] = useState([]);
  //////////////////////////attachments///////////////////////////
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  ////////////////////////////////////////////////////////
  const [patientList, setPatientList] = useState([]);
  const [errors, setErrors] = useState({});
  const [prescriptionId, setPrescriptionId] = useState('');
  const [appointmentDateTime, setAppointmentDateTime] = useState('');
  const [editDob, setEditDob] = useState(null);
  const [patientPicture, setPatientPicture] = useState(null);
  const [patientInfo, setPatientInfo] = useState({
    id: '',
    name: '',
    dob: {
      years: '',
      months: '',
      days: '',
      hours: '',
    },
    phoneNumber: '',
    gender: isFemaleSelected(userInfo?.email) ? 'female' : 'male',
    status: 'created',
    nid: '',
    patientId: '',
    doctorId: userInfo.id,
    email: '',
    patientDetails: {},
    organizationId: activeOrganization.id,
  });
  const [appointmentInfo, setAppointmentInfo] = useState({
    id: '',
    patientId: '',
    status: '',
    appointmentDateTime: '',
    organizationId: activeOrganization.id,
  });

  //for video calling
  // const socket = io('localhost:3009');
  let socket = null;
  if (userInfo?.doctorDetails?.isVideoCall) {
    socket = io('https://myrx.health:3009');
  }
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [onlineUserExist, setOnlineUserExist] = useState(false);
  const [startCalling, setStartCalling] = useState(1);
  const [startVideo, setStartVideo] = useState(0);
  const [callTimeoutHandler, setCallTimeoutHandler] = useState(null);
  const [shouldClearTimeout, setShouldClearTimeout] = useState(false);
  const [roomName, setRoomName] = useState('');

  const cancelCall = () => {
    setStartCalling(0);
    setStartVideo(0);
    setShouldClearTimeout(true);
    setOpenVideoModal(false);
  };

  useEffect(() => {
    // client-side
    if (socket) {
      if (socket && socket.connected) {
        console.log('socket conneted1 ', socket.id);
        // if(userInfo?.id && userInfo?.id !== 'doctor'){
        socket.emit('setUsername', userInfo.id);
        // }
        // if (patientInfo.id) {
        socket.emit('checkUsername', patientInfo.id);
        // }
      }
      socket.on('connect', () => {
        console.log('socket connected ', socket.id); // x8WIv7-mJelg7on_ALbx
        socket.emit('setUsername', userInfo.id);
        socket.emit('checkUsername', patientInfo.id);
      });

      socket.on('privateMessage', (data) => {
        console.log('message from cleint ', data);
        const message = data;
        console.log('come from data ', message);
        if (message?.type === 'confirm_startCall') {
          console.log('updating startvideo status');
          setStartVideo(1);
        } else if (message?.type === 'endCall') {
        }
      });

      socket.on('checkUsername', (data) => {
        console.log('checking online patient');
        if (data?.username === patientInfo.id && data?.userOnline) {
          setOnlineUserExist(true);
        } else {
          setOnlineUserExist(false);
        }
      });

      socket.on('disconnect', () => {
        console.log('disconnected ', socket.id); // undefined
      });
    }
  }, [patientInfo]);

  if (socket && socket.connected) {
    socket.on('checkUsername', (data) => {
      // console.log('checking online patient', data, patientInfo);
      if (data?.username === patientInfo.id && data?.userOnline) {
        setOnlineUserExist(true);
      } else {
        setOnlineUserExist(false);
      }
    });

    socket.on('disconnect', () => {
      console.log('disconnected ', socket.id); // undefined
    });
  }

  const rxItemName = (itemName) => {
    if (prescriptionItems && prescriptionItems?.items) {
      const itemNames = {
        'chief-complain': 'Chief Complain',
        history: 'History',
        diagnosis: 'Diagnosis',
        'on-examination': 'On Examinations',
        investigation: 'Investigations',
        rx: 'Rx',
        rehabilitation: 'Rehabilitation',
        'follow-up': 'Follow-Up',
        advice: 'Advice',
        infertility: 'Infertility',
        'eye-glass': 'Eye-Glass',
        'special-note': 'Special Note',
        signature: 'Signature',
        predictions: 'Predictions',
      };
      const item = prescriptionItems?.items[itemName];
      return item?.alterName ? item.alterName : itemNames[itemName];
    } else {
      return '';
    }
  };

  const resetPrescription = (templateSelect = false) => {
    setSelectedTemplates([]);
    setSelectedChiefComplains([]);
    setSelectedTreatmentPlans([]);
    setSelectedComplainGroups([]);
    setSelectedDiagnosis([]);
    setSelectedStage({});
    setSelectedDiagnosisGroups([]);
    setSelectedAdvices([]);
    setSelectedAdvicesGroups([]);
    setSelectedFollowUps([]);
    setSelectedMedicines([]);
    setSelectedMedicineGroups([]);
    setSelectedInvestigations([]);
    setSelectedInvestigationGroups([]);
    setSelectedInfertilities({});
    setSelectedInfertilityManGroups([]);
    setSelectedInfertilityWomenGroups([]);
    setSelectedSpecialNotes([]);
    setSelectedEyeGlass({
      isPlano: false,
      leftPlano: false,
      r: { sph: '', cyl: '', axis: '' },
      l: { sph: '', cyl: '', axis: '' },
      remarks: [],
    });
    setSelectedRehabilitation({
      orthoses: [],
      exercises: [],
      physicalTherapies: [],
    });
    setSelectedHistories({});
    setSelectedOnExamination({});
    if (!templateSelect) {
      setPatientInfo({
        ...patientInfo,
        id: '',
        name: '',
        dob: {},
        phoneNumber: '',
        gender: isFemaleSelected(userInfo?.email) ? 'female' : 'male',
        status: 'created',
        nid: '',
        patientId: '',
        email: '',
        doctorId: userInfo.id,
        organizationId: activeOrganization.id,
        image: null,
        patientDetails: {},
      });
    }
    setAppointmentInfo({
      id: '',
      patientId: '',
      status: '',
      appointmentDateTime: '',
      organizationId: activeOrganization.id,
      reset: false,
    });
    setPrescriptionId('');
    setPatientPicture(null);
    setEditDob(null);
  };

  const callbackPrint = useReactToPrint({
    content: () => printRef.current,
  });

  const settingPatientData = (patient) => {
    let dob = patient.dob;
    const utcMoment = moment.utc(dob);
    const localMoment = utcMoment.local();
    const formattedLocalTime = localMoment.format('YYYY-MM-DD HH:mm:ss');
    setPatientInfo({
      ...patientInfo,
      id: patient?.id,
      name: patient?.name,
      dob: formattedLocalTime,
      gender: patient?.gender,
      phoneNumber: patient?.phoneNumber,
      email: patient?.email,
      patientDetails: patient?.patientDetails,
      patientId: getPatientIdForDoctor(patient?.patientIdForDoctor, userInfo),
    });
  };

  useEffect(() => {
    const history = location?.state?.history;
    setEditDob(location?.state?.dob);
    if (history === 'editFromHistory') {
      let patientHistory = location?.state?.prescription;
      let patientData = patientHistory?.patientId;
      patientData.dob = patientData?.dob;
      settingPatientData(patientData);
      setNote(location?.state?.note);

      console.log(patientHistory, 'patientHistorypatientHistory');

      setAppointmentInfo({
        id: patientHistory?.appointmentId?.id,
        patientId: patientHistory?.patientId?.id,
        status: 'completed',
        appointmentDateTime: patientHistory?.appointmentId?.appointmentDateTime,
        organizationId: activeOrganization.id,
      });
      setPrescriptionId(patientHistory.id);
      setAppointmentDateTime(
        patientHistory?.appointmentId?.appointmentDateTime,
      );
      patientHistory?.chiefComplains
        ? setSelectedChiefComplains([...patientHistory?.chiefComplains])
        : setSelectedChiefComplains([]);
      patientHistory?.treatmentPlans
        ? setSelectedTreatmentPlans([...patientHistory?.treatmentPlans])
        : setSelectedTreatmentPlans([]);
      patientHistory?.diagnoses
        ? setSelectedDiagnosis([...patientHistory?.diagnoses])
        : setSelectedDiagnosis([]);
      patientHistory?.staging
        ? setSelectedStage({ ...patientHistory?.staging })
        : setSelectedStage({});
      patientHistory?.advices
        ? setSelectedAdvices([...patientHistory?.advices])
        : setSelectedAdvices([]);
      patientHistory?.followUps
        ? setSelectedFollowUps([...patientHistory?.followUps])
        : setSelectedFollowUps([]);
      setSelectedMedicines([...patientHistory?.medicines]);
      patientHistory?.histories
        ? setSelectedHistories({ ...patientHistory?.histories })
        : setSelectedHistories({});
      patientHistory?.onExaminations
        ? setSelectedOnExamination({ ...patientHistory?.onExaminations })
        : setSelectedOnExamination({});
      patientHistory?.investigations
        ? setSelectedInvestigations([...patientHistory?.investigations])
        : setSelectedInvestigations([]);
      patientHistory?.eyeGlass
        ? setSelectedEyeGlass({ ...patientHistory?.eyeGlass })
        : setSelectedEyeGlass({
            isPlano: false,
            leftPlano: false,
            rightPlano: false,
            r: { sph: '', cyl: '', axis: '' },
            l: { sph: '', cyl: '', axis: '' },
            remarks: [],
          });
      patientHistory?.infertilities
        ? setSelectedInfertilities({ ...patientHistory?.infertilities })
        : setSelectedInfertilities({});
      patientHistory?.rehabilitation
        ? setSelectedRehabilitation({ ...patientHistory?.rehabilitation })
        : setSelectedRehabilitation({});
      patientHistory?.specialNotes
        ? setSelectedSpecialNotes([...patientHistory?.specialNotes])
        : setSelectedSpecialNotes([]);
      const rehabData = patientHistory?.rehabilitation
        ? patientHistory.rehabilitation
        : selectedRehabilitation;
      setSelectedRehabilitation({ ...rehabData });
    } else if (history === 'editFromList') {
      let appointmentData = location?.state?.appointmentInfo;
      let patientData = appointmentData?.patientId;
      patientData.dob = patientData?.dob;
      settingPatientData(patientData);

      setAppointmentInfo({
        id: appointmentData?.id,
        patientId: appointmentData?.patientId?.id,
        status: 'completed',
        appointmentDateTime: appointmentData?.appointmentDateTime,
        organizationId: activeOrganization.id,
      });
      setAppointmentDateTime(appointmentData?.appointmentDateTime);
    } else if (history === 'new') {
      const patInfo = location?.state?.patientData;
      const utcMoment = moment.utc(patInfo?.dob);
      const localMoment = utcMoment.local();
      const formattedLocalTime = localMoment.format('YYYY-MM-DD HH:mm:ss');
      const patentModifiedInfo = {
        id: patInfo?.id,
        email: patInfo?.email,
        name: patInfo?.name,
        phoneNumber: patInfo?.phoneNumber,
        gender: patInfo?.gender,
        dob: formattedLocalTime,
        patientDetails: patInfo?.patientDetails,
      };
      setPatientInfo({
        ...patentModifiedInfo,
        status: 'created',
        doctorId: userInfo.id,
        organizationId: activeOrganization.id,
      });
      // OLD CODE
      // setPatientInfo({
      //   ...location?.state?.patientData,
      //   dob: location?.state?.patientData?.dob,
      //   status: 'created',
      //   doctorId: userInfo.id,
      //   organizationId: activeOrganization.id,
      // });
    } else if (history === 'completed') {
      let appointment = location?.state?.appointmentInfo;
      let patientData = appointment?.patientId;
      patientData.dob = patientData?.dob;
      settingPatientData(patientData);

      setAppointmentInfo({
        ...appointmentInfo,
        id: appointment.id,
        patientId: appointment.patientId.id,
        status: appointment.status,
        appointmentDateTime: appointment.appointmentDateTime,
      });
    } else if (history === 'onlyAppointment') {
      let appointment = location?.state?.appointment;
      let patientData = appointment?.patientId;
      patientData.dob = patientData?.dob;
      settingPatientData(patientData);
      appointmentInfo.id = appointment?.id;
      appointmentInfo.patientId = appointment?.patientId.id;
      appointmentInfo.status = appointment?.status;
      appointmentInfo.appointmentDateTime = appointment?.appointmentDateTime;
      setAppointmentInfo({ ...appointmentInfo });
    }
  }, []);

  useEffect(() => {
    if (patientInfo?.search === 'yes') {
      setSelectedChiefComplains([]);
      setSelectedTreatmentPlans([]);
      setSelectedDiagnosis([]);
      setSelectedStage({});
      setSelectedMedicines([]);
      setSelectedAdvices([]);
      setSelectedFollowUps([]);
      setSelectedEyeGlass({
        isPlano: false,
        leftPlano: false,
        r: { sph: '', cyl: '', axis: '' },
        l: { sph: '', cyl: '', axis: '' },
        remarks: [],
      });
      setSelectedRehabilitation({
        orthoses: [],
        exercises: [],
        physicalTherapies: [],
      });
      setSelectedHistories({});
      setSelectedOnExamination(onExaminationData);
      setSelectedInvestigations([]);
      // get last prescription info
      if (patientInfo?.id !== '') {
        getPatientPrescriptionLast();
      }
    }
  }, [patientInfo]);

  useEffect(() => {
    if (
      location?.state?.appointmentInfo?.id &&
      location?.state?.appointmentInfo?.patientId?.id &&
      appointmentInfo?.reset !== false
    ) {
      getPatientAppointmentAndPrescription();
    }
  }, [appointmentInfo]);

  const { isLoading: isMedicineLoading, refetch: getMedicineList } =
    useGetRequest(
      'getMedicines',
      `medicines?page=${currentPage}&perPage=${perPage}`,
      (data) => {
        console.log('napa');
        if (currentPage > 1) {
          setMedicines([...medicines, ...data.data]);
        } else {
          setMedicines(data.data);
          setTotalItem(data.total);
        }
      },
      (e) => {
        console.log(e);
      },
    );

  const { isLoading: isInstructionLoading, refetch: getInstructionList } =
    useGetRequest(
      'instructionList',
      `instructions`,
      (data) => {
        setInstructions(data.data);
      },
      (e) => {
        console.log(e);
      },
    );

  const { isLoading: isMedGrpLoading, refetch: getMedGrpList } = useGetRequest(
    'med-grp-list',
    `prescriptions/groups?organizationId=${activeOrganization.id}`,
    (data) => {
      setMedicineGroups(data);
    },
    (e) => {
      console.log(e);
    },
  );

  const {
    isLoading: isPatientPictureLoading,
    refetch: getPatientProfilePicture,
  } = useGetRequest(
    'get-patient-img',
    `auth/patient-picture/${patientInfo?.id}`,
    (data) => {
      setPatientPicture(data);
    },
    (e) => {
      console.log('error message: ', e.message);
    },
  );

  useEffect(() => {
    if (patientInfo?.id) {
      getPatientProfilePicture();
    }
  }, [patientInfo?.id]);

  useEffect(() => {
    getInstructionList();
    getMedGrpList();
  }, []);

  useEffect(() => {
    getMedicineList();
  }, [currentPage]);

  const handlePrintStatus = (status) => {
    setPrintStatus(status);
  };
  const closeOnExaminationModal = () => {
    setShowOnExamination(false);
  };
  const handleOnExaminations = (selectedOnExaminations) => {
    setSelectedOnExamination({ ...selectedOnExaminations });
  };

  const createDataObject = (data, type) => {
    let obj = { query: '', note: '' };
    switch (type) {
      case 'diagnosis':
        obj = obj;
        break;
      case 'chiefComplain':
        obj = {
          duration: '',
          unit: 'day(s)',
          note: '',
        };
        break;
      case 'advice':
        obj = { note: '' };
      case 'investigation':
        obj = { instruction: '', result: '', unit: '' };
    }
    return data.map((item) => {
      return { ...obj, name: item };
    });
  };

  const unSelectTemplate = (data) => {
    if (data?.medicines?.length > 0) {
      let itemsToDelete = data?.medicines?.map((item) => item.id);
      setSelectedMedicines((prevItems) =>
        prevItems.filter((item) => !itemsToDelete.includes(item.id)),
      );
    }
    if (data?.chiefComplains?.length > 0) {
      let itemsToDelete = data?.chiefComplains?.map((item) => item.name);
      setSelectedChiefComplains((prevItems) =>
        prevItems.filter((item) => !itemsToDelete.includes(item.name)),
      );
    }
    if (data?.treatmentPlans?.length > 0) {
      let itemsToDelete = data?.treatmentPlans?.map((item) => item.name);
      setSelectedTreatmentPlans((prevItems) =>
        prevItems.filter((item) => !itemsToDelete.includes(item.name)),
      );
    }
    if (data?.diagnoses?.length > 0) {
      let itemsToDelete = data?.diagnoses?.map((item) => item.name);
      setSelectedDiagnosis((prevItems) =>
        prevItems.filter((item) => !itemsToDelete.includes(item.name)),
      );
    }
    if (data?.investigations?.length > 0) {
      let itemsToDelete = data?.investigations?.map((item) => item.name);
      setSelectedInvestigations((prevItems) =>
        prevItems.filter((item) => !itemsToDelete.includes(item.name)),
      );
    }
    if (data?.advices?.length > 0) {
      let itemsToDelete = data?.advices?.map((item) => item.name);
      setSelectedAdvices((prevItems) =>
        prevItems.filter((item) => !itemsToDelete.includes(item.name)),
      );
    }
    if (data?.followUps?.length > 0) {
      let itemsToDelete = data?.followUps?.map((item) => item.name);
      setSelectedFollowUps((prevItems) =>
        prevItems.filter((item) => !itemsToDelete.includes(item.name)),
      );
    }
    if (data?.specialNotes?.length > 0) {
      let itemsToDelete = data?.specialNotes?.map((item) => item.name);
      setSelectedSpecialNotes((prevItems) =>
        prevItems.filter((item) => !itemsToDelete.includes(item.name)),
      );
    }
    data?.histories && Object.keys(data?.histories).length === 0
      ? setSelectedHistories(selectedHistories)
      : setSelectedHistories({});
    data?.onExaminations && Object.keys(data?.onExaminations).length === 0
      ? setSelectedOnExamination(selectedOnExamination)
      : setSelectedOnExamination({});
    // data?.infertilities && Object.keys(data?.infertilities).length === 0
    //   ? setSelectedInfertilities(selectedInfertilities)
    //   : setSelectedInfertilities({});
    data?.eyeGlass && Object.keys(data?.eyeGlass).length === 0
      ? setSelectedEyeGlass(selectedEyeGlass)
      : setSelectedEyeGlass({
          isPlano: false,
          leftPlano: false,
          rightPlano: false,
          r: { sph: '', cyl: '', axis: '' },
          l: { sph: '', cyl: '', axis: '' },
          remarks: [],
        });
    data?.rehabilitation && Object.keys(data?.rehabilitation).length === 0
      ? setSelectedRehabilitation(selectedRehabilitation)
      : setSelectedRehabilitation({});
  };

  const toggleTemplateSelection = (selectedItem) => {
    const type = selectedItem?.type;
    switch (type) {
      case 'Prescription Template':
        if (selectedTemplates?.some((item) => item.id === selectedItem.id)) {
          setSelectedTemplates(
            selectedTemplates?.filter((item) => item.id !== selectedItem.id),
          );
          unSelectTemplate(selectedItem);
        } else {
          setSelectedTemplates([...selectedTemplates, selectedItem]);
          setPrescriptionData(selectedItem);
        }
        break;
      case 'Diagnosis group':
        handleGroupData(
          selectedItem,
          selectedDiagnosisGroups,
          setSelectedDiagnosisGroups,
          selectedDiagnosis,
          setSelectedDiagnosis,
          type,
        );
        break;
      case 'Chief complain group':
        handleGroupData(
          selectedItem,
          selectedComplainGroups,
          setSelectedComplainGroups,
          selectedChiefComplains,
          setSelectedChiefComplains,
          type,
        );
        break;
      case 'Medicine group':
        if (
          selectedMedicineGroups?.some((item) => item.id === selectedItem.id)
        ) {
          unSelectMedicineGroup(selectedItem);
        } else {
          selectMedicineGroup(selectedItem);
        }
        break;
      case 'Investigation group':
        handleGroupData(
          selectedItem,
          selectedInvestigationGroups,
          setSelectedInvestigationGroups,
          selectedInvestigations,
          setSelectedInvestigations,
          type,
        );
        break;
      case 'Advice group':
        handleGroupData(
          selectedItem,
          selectedAdvicesGroups,
          setSelectedAdvicesGroups,
          selectedAdvices,
          setSelectedAdvices,
          type,
        );
        break;
      case 'Patient':
        setPatientInfo({
          ...patientInfo,
          id: selectedItem?.id,
          name: selectedItem?.name,
          dob: selectedItem?.dob,
          phoneNumber: selectedItem?.phoneNumber,
          search: 'yes',
          email: selectedItem?.email,
        });
        break;
    }
  };

  const selectMedicineGroup = (medicineGroup) => {
    const newMedicines = medicineGroup?.medicines?.filter(
      (medicine) => !selectedMedicines.some((data) => data.id === medicine.id),
    );
    setSelectedMedicines([...selectedMedicines, ...newMedicines]);
    setSelectedMedicineGroups([...selectedMedicineGroups, medicineGroup]);
  };

  const unSelectMedicineGroup = (medicineGroup) => {
    const otherGroups = selectedMedicineGroups.filter(
      (data) => data.name !== medicineGroup.name,
    );
    setSelectedMedicineGroups([...otherGroups]);
    let itemsToDelete = medicineGroup?.medicines?.map((item) => item.id);
    if (otherGroups?.length) {
      const otherGroupItems = otherGroups.flatMap((obj) =>
        obj?.medicines?.map((item) => item.id),
      );
      itemsToDelete = itemsToDelete.filter(
        (item) => !otherGroupItems?.includes(item),
      );
    }
    setSelectedMedicines((prevItems) =>
      prevItems.filter((item) => !itemsToDelete.includes(item.id)),
    );
  };

  const removeDuplicateItem = (items) => {
    return items.reduce((acc, curr) => {
      if (!acc.some((item) => item.name === curr.name)) {
        acc.push(curr);
      }
      return acc;
    }, []);
  };

  const setPrescriptionData = (prescription) => {
    const chiefComplains = prescription?.chiefComplains?.length
      ? removeDuplicateItem([
          ...selectedChiefComplains,
          ...prescription.chiefComplains,
        ])
      : selectedChiefComplains;
    setSelectedChiefComplains(chiefComplains);

    const treatmentPlans = prescription?.treatmentPlans?.length
      ? removeDuplicateItem([
          ...selectedTreatmentPlans,
          ...prescription.treatmentPlans,
        ])
      : selectedTreatmentPlans;
    setSelectedTreatmentPlans(treatmentPlans);

    const diagnoses = prescription?.diagnoses?.length
      ? removeDuplicateItem([...selectedDiagnosis, ...prescription.diagnoses])
      : selectedDiagnosis;
    setSelectedDiagnosis(diagnoses);

    const advices = prescription?.advices?.length
      ? removeDuplicateItem([...selectedAdvices, ...prescription.advices])
      : selectedAdvices;
    setSelectedAdvices(advices);

    const followUps = prescription?.followUps?.length
      ? [...new Set([...selectedFollowUps, ...prescription.followUps])]
      : selectedFollowUps;
    setSelectedFollowUps(followUps);

    const medicines = prescription?.medicines?.length
      ? removeDuplicateItem([...selectedMedicines, ...prescription.medicines])
      : selectedMedicines;
    setSelectedMedicines(medicines);

    const investigations = prescription?.investigations?.length
      ? removeDuplicateItem([
          ...selectedInvestigations,
          ...prescription.investigations,
        ])
      : selectedInvestigations;
    setSelectedInvestigations(investigations);

    prescription?.histories
      ? setSelectedHistories({ ...prescription?.histories })
      : setSelectedHistories(selectedHistories);

    prescription?.onExaminations
      ? setSelectedOnExamination({ ...prescription?.onExaminations })
      : setSelectedOnExamination(selectedOnExamination);

    prescription?.infertilities
      ? setSelectedInfertilities({ ...prescription?.infertilities })
      : setSelectedInfertilities(selectedInfertilities);

    prescription?.eyeGlass
      ? setSelectedEyeGlass({ ...prescription?.eyeGlass })
      : setSelectedEyeGlass(selectedEyeGlass);
    prescription?.rehabilitation
      ? setSelectedRehabilitation({ ...prescription?.rehabilitation })
      : setSelectedRehabilitation(selectedRehabilitation);
    // prescription?.specialNotes
    //   ? setSelectedSpecialNotes([...prescription?.specialNotes])
    //   : setSelectedSpecialNotes([]);

    // const specialNotes = prescription?.specialNotes?.length
    //   ? removeDuplicateItem([
    //       ...selectedSpecialNotes,
    //       ...prescription?.specialNotes,
    //     ])
    //   : selectedSpecialNotes;

    const specialNotes = prescription?.specialNotes?.length
      ? prescription?.specialNotes
      : selectedSpecialNotes;
    setSelectedSpecialNotes([...specialNotes]);
  };

  const getPatientPrescriptionLast = () => {
    const url = `prescriptions?organizationId=${activeOrganization.id}&patientId=${patientInfo.id}`;
    getRequest(url)
      .then((data) => {
        let content = data.data;
        let length = content.length;
        if (length) {
          let prescription =
            length > 0 ? (length === 1 ? content[0] : content[length - 1]) : {};
          setPrescriptionId(prescription.id);
          setPrescriptionData(prescription);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const {
    isLoading: isPatientAppointment,
    refetch: getPatientAppointmentAndPrescription,
  } = useGetRequest(
    'getPatientLastPrescription',
    'prescriptions?organizationId=' +
      activeOrganization.id +
      '&appointmentId=' +
      location?.state?.appointmentInfo?.id +
      '&patientId=' +
      location?.state?.appointmentInfo?.patientId?.id,
    (data) => {
      let content = data.data;
      let length = content.length;
      if (length) {
        let prescription =
          length > 0 ? (length === 1 ? content[0] : content[length - 1]) : {};
        setPrescriptionId(prescription?.id);
        setPrescriptionData(prescription);
        setNote(data?.note?.note);
      }
    },
    (error) => {
      console.log(error, 'error');
    },
  );

  const handleSpecialNote = (data) => {
    return data?.map((item) => {
      return {
        groupName: item?.groupName,
        note: item?.note,
      };
    });
  };

  const handleSubmit = async (submitType) => {
    toast.dismiss();
    setLoadingStatus(true);
    if (
      patientInfo.id === '' ||
      patientInfo.search === 'yes' ||
      patientInfo.status === 'created'
    ) {
      patientInfo.organizationId = activeOrganization.id;
      patientInfo.dob = patientInfo.id
        ? typeof patientInfo.dob == 'object'
          ? ageConvertToDateTime(patientInfo.dob)
          : editDob
        : ageConvertToDateTime(patientInfo.dob);

      await postRequest('patients', patientInfo)
        .then((data) => {
          patientInfo.dob = data.dob;
          patientInfo.id = data.id;
          patientInfo.patientId = data?.patientId;
          patientInfo.patientIdForDoctor = data?.patientIdForDoctor;
          setEditDob(data.dob);
          settingPatientData(patientInfo);
          appointmentInfo.patientId = data.id;
          setAppointmentInfo({
            ...appointmentInfo,
            reset: false,
          });
        })
        .catch((error) => {
          setLoadingStatus(false);
          const errorMsg = error.message.split(',');
          toast.error(errorMsg[0], {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    if (patientInfo.id !== '' && appointmentInfo.status !== 'new') {
      appointmentInfo.status = 'completed';
      appointmentInfo.organizationId = activeOrganization.id;
      appointmentInfo.appointmentDateTime = moment().format('YYYY-MM-DD HH:mm');

      const requestUrl =
        appointmentInfo.id &&
        moment(appointmentDateTime).format('YYYY-MM-DD') ===
          moment().format('YYYY-MM-DD')
          ? patchRequest('appointments/' + appointmentInfo.id, appointmentInfo)
          : postRequest('appointments', appointmentInfo);

      await requestUrl
        .then((data) => {
          appointmentInfo.id = data.id;
          setAppointmentInfo({
            ...appointmentInfo,
            reset: false,
          });
          setAppointmentStatus(data.status);
          setAppointmentDateTime(data.appointmentDateTime);
        })
        .catch((error) => {
          setLoadingStatus(false);
          console.log(error);
        });
    }
    let formData = new FormData();
    if (appointmentInfo.id !== '') {
      let prescriptionArr = {
        patientId: patientInfo.id,
        appointmentId: appointmentInfo.id,
        note: { note },
        organizationId: activeOrganization.id,
        chiefComplains: selectedChiefComplains,
        onExaminations: selectedOnExamination,
        diagnoses: selectedDiagnosis,
        staging: selectedStage,
        histories: selectedHistories,
        medicines: selectedMedicines,
        advices: selectedAdvices,
        rehabilitation: selectedRehabilitation,
        followUps: selectedFollowUps,
        investigations: selectedInvestigations,
        treatmentPlans: selectedTreatmentPlans,
        eyeGlass: selectedEyeGlass,
        infertilities: selectedInfertilities,
        specialNotes: handleSpecialNote(selectedSpecialNotes),
        medicineIds: selectedMedicines.length
          ? selectedMedicines.map((item) => item.id)
          : [],
        // medicineIds: selectedMedicines.length
        //   ? selectedMedicines.map((item) => item.id)
        //   : [],
      };

      const requestPrescription =
        prescriptionId &&
        moment(appointmentDateTime).format('YYYY-MM-DD') ===
          moment().format('YYYY-MM-DD')
          ? patchRequest('prescriptions/' + prescriptionId, prescriptionArr)
          : postRequest('prescriptions', prescriptionArr);

      await requestPrescription
        .then((data) => {
          setAppointmentStatus('completed');
          // setNote('');
          if (file && title) {
            formData.append('name', title);
            formData.append('patientId', patientInfo.id);
            formData.append('prescriptionId', data.id);
            formData.append('file', file);
            handleSubmitAttachment(formData);
          }
          setPrescriptionId(data.id);

          if (submitType === 'saveAndPrint') {
            setLoadingStatus(false);
            callbackPrint();
          } else {
            // toast.success('Successfully created', {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            setTimeout(function () {
              setLoadingStatus(false);
            }, 1500);
          }
        })
        .catch((error) => {
          setLoadingStatus(false);
          console.log(error);
        });
    }
  };
  const getErrorMessage = (inputName) => {
    return errors[inputName] ? errors[inputName] : '';
  };

  const handleSubmitAttachment = (formData) => {
    postRequest('attachments', formData).then((data) => {
      console.log(data);
    });
  };

  const actualWidth = prescriptionSetting?.page?.width?.quantity;

  const closeModalOutside = () => {
    setShowHistory(false);
    setShowInvestigation(false);
    setShowOnExamination(false);
    setShowDiagnosis(false);
    setShowAdvice(false);
    setShowInfertility(false);
    setShowFollowUp(false);
    setShowEyeGlass(false);
    setShowRehabilitation(false);
    setShowPredictions(false);
    setEditMedicineId('');
    setSelectedOnExaminationTab('observation');
  };

  const deletePrescriptionItem = (type, itemId) => {
    if (type === 'diagnosis') {
      setSelectedDiagnosis(
        selectedDiagnosis.filter((diagnosis) => diagnosis.name !== itemId),
      );
    } else if (type === 'investigation') {
      setSelectedInvestigations(
        selectedInvestigations.filter(
          (investigation) => investigation.name !== itemId,
        ),
      );
    } else if (type === 'chiefComplain') {
      setSelectedChiefComplains(
        selectedChiefComplains.filter((chief) => chief.name !== itemId),
      );
    } else if (type === 'treatmentPlan') {
      setSelectedTreatmentPlans(
        selectedTreatmentPlans?.filter((treat) => treat.name !== itemId),
      );
    } else if (type === 'followup') {
      setSelectedFollowUps(
        selectedFollowUps.filter((followup) => followup !== itemId),
      );
    } else if (type === 'onExamination') {
      const observations = [
        ...selectedOnExamination.observations.filter(
          (observation) => observation.name !== itemId,
        ),
      ];
      selectedOnExamination.observations = observations;
      setSelectedOnExamination({ ...selectedOnExamination });
    } else if (type === 'eyeGlassTable') {
      setSelectedEyeGlass({
        isPlano: false,
        leftPlano: false,
        r: { sph: '', cyl: '', axis: '' },
        l: { sph: '', cyl: '', axis: '' },
        remarks: [],
      });
    } else if (type === 'eyeGlassRemark') {
      const result = selectedEyeGlass.remarks.filter(
        (remark) => remark !== itemId,
      );
      setSelectedEyeGlass({ ...selectedEyeGlass, remarks: result });
    }
  };

  const chiefComplainView = (element, type) => {
    if (type === 'print' && selectedChiefComplains.length === 0) {
      return false;
    }
    return (
      <ChiefComplainView
        element={element}
        key={'chiefComplain'}
        prescriptionItems={prescriptionItems}
        setIsComplainModal={setIsComplainModal}
        selectedChiefComplains={selectedChiefComplains}
        setSelectedChiefComplains={setSelectedChiefComplains}
        deletePrescriptionItem={deletePrescriptionItem}
      />
    );
  };

  const treatmentPlanView = (element, type) => {
    if (type === 'print' && selectedTreatmentPlans?.length === 0) {
      return false;
    }
    return (
      <TreatmentPlanView
        element={element}
        key={'treatmentPlan'}
        prescriptionItems={prescriptionItems}
        setIsTreatmentPlanModal={setIsTreatmentPlanModal}
        selectedTreatmentPlans={selectedTreatmentPlans}
        setSelectedTreatmentPlans={setSelectedTreatmentPlans}
        deletePrescriptionItem={deletePrescriptionItem}
      />
    );
  };

  const historyView = (element, type) => {
    if (type === 'print' && !isExistAnyHistory(selectedHistories)) {
      return false;
    }
    return (
      <HistoryView
        element={element}
        key={'history'}
        setShowHistory={setShowHistory}
        selectedHistories={selectedHistories}
        setSelectedHistories={setSelectedHistories}
        deletePrescriptionItem={deletePrescriptionItem}
        prescriptionItems={prescriptionItems}
      />
    );
  };

  const onExaminationView = (element, type) => {
    if (type === 'print' && !isExistAnyOnExamination(selectedOnExamination)) {
      return false;
    }
    return (
      <OnExaminationView
        element={element}
        key={'onExamination'}
        deletePrescriptionItem={deletePrescriptionItem}
        selectedOnExamination={selectedOnExamination}
        setShowOnExamination={setShowOnExamination}
        prescriptionItems={prescriptionItems}
        setSelectedOnExamination={setSelectedOnExamination}
      />
    );
  };

  const predictionsView = (element, type) => {
    if (type === 'print' && !isExistAnyOnExamination(selectedOnExamination)) {
      return false;
    }
    return (
      <PredictionsView
        element={element}
        key={'onExamination'}
        deletePrescriptionItem={deletePrescriptionItem}
        selectedOnExamination={selectedOnExamination}
        setShowPredictions={setShowPredictions}
        prescriptionItems={prescriptionItems}
        setSelectedOnExamination={setSelectedOnExamination}
      />
    );
  };

  const investigationView = (element, type) => {
    if (type === 'print' && selectedInvestigations.length === 0) {
      return false;
    }
    return (
      <InvestigationView
        element={element}
        key={'investigation'}
        prescriptionItems={prescriptionItems}
        setShowInvestigation={setShowInvestigation}
        selectedInvestigations={selectedInvestigations}
        setSelectedInvestigations={setSelectedInvestigations}
        deletePrescriptionItem={deletePrescriptionItem}
      />
    );
  };

  const adviceView = (element, type) => {
    if (type === 'print' && selectedAdvices.length === 0) {
      return false;
    }
    return (
      <AdviceView
        element={element}
        key={'advice'}
        prescriptionItems={prescriptionItems}
        setShowAdvice={setShowAdvice}
        selectedAdvices={selectedAdvices}
        setSelectedAdvices={setSelectedAdvices}
        deletePrescriptionItem={deletePrescriptionItem}
      />
    );
  };

  const followUpView = (element, type) => {
    if (type === 'print' && selectedFollowUps.length === 0) {
      return false;
    }
    return (
      <FollowUpView
        element={element}
        key={'followUp'}
        prescriptionItems={prescriptionItems}
        setShowFollowUp={setShowFollowUp}
        selectedFollowUps={selectedFollowUps}
        setSelectedFollowUps={setSelectedFollowUps}
        deletePrescriptionItem={deletePrescriptionItem}
      />
    );
  };

  const eyeGlassView = (element, type) => {
    if (type === 'print' && !isExistAnyEyeGlass(selectedEyeGlass)) {
      return false;
    }
    return (
      <EyeGlassView
        selectedEyeGlass={selectedEyeGlass}
        setSelectedEyeGlass={setSelectedEyeGlass}
        setShowEyeGlass={setShowEyeGlass}
        deletePrescriptionItem={deletePrescriptionItem}
        element={element}
        key={'eyeGlass'}
      />
    );
  };

  const viewDiagnosis = (element, type) => {
    if (
      type === 'print' &&
      selectedDiagnosis.length === 0 &&
      !selectedStage?.isShow
    ) {
      return false;
    }
    return (
      <DiagnosisView
        key={'diagnosis'}
        selectedDiagnosis={selectedDiagnosis}
        setSelectedDiagnosis={setSelectedDiagnosis}
        setShowDiagnosis={setShowDiagnosis}
        prescriptionItems={prescriptionItems}
        deletePrescriptionItem={deletePrescriptionItem}
        selectedStage={selectedStage}
        element={element}
      />
    );
  };

  const viewRehabilitation = (element, type) => {
    if (type === 'print' && !isExistAnyRehabData(selectedRehabilitation)) {
      return false;
    }
    return (
      <RehabilitationView
        selectedRehabilitation={selectedRehabilitation}
        setSelectedRehabilitation={setSelectedRehabilitation}
        setShowRehabilitation={setShowRehabilitation}
        element={element}
        key={'rehabilitation'}
      />
    );
  };

  const viewRx = (element, type) => {
    if (type === 'print' && selectedMedicines.length === 0) {
      return false;
    }
    return (
      <RxView
        selectedMedicines={selectedMedicines}
        setSelectedMedicines={setSelectedMedicines}
        setShowRx={setShowRx}
        // deletePrescriptionItem={deletePrescriptionItem}
        instructions={instructions}
        setInstructions={setInstructions}
        prescriptionItems={prescriptionItems}
        editMedicineId={editMedicineId}
        setEditMedicineId={setEditMedicineId}
        element={element}
        type={type}
        key={'rx'}
      />
    );
  };

  const infertilityView = (element, type) => {
    if (type === 'print' && !isExistAnyInfertilityData(selectedInfertilities)) {
      return false;
    }
    return (
      <InfertilityView
        element={element}
        key={'infertility'}
        setShowInfertility={setShowInfertility}
        selectedInfertilities={selectedInfertilities}
        setSelectedInfertilities={setSelectedInfertilities}
      />
    );
  };

  const specialNoteView = (element, type) => {
    if (type === 'print' && selectedSpecialNotes.length === 0) {
      return false;
    }
    return (
      <SpecialNoteView
        selectedSpecialNotes={selectedSpecialNotes}
        setSelectedSpecialNotes={setSelectedSpecialNotes}
        setShowSpecialNote={setShowSpecialNote}
        prescriptionItems={prescriptionItems}
        element={element}
        key={'specialNote'}
      />
    );
  };

  const signatureView = (type) => {
    if (type === 'print' && !userInfo?.signature) {
      return false;
    }
    return (
      <SignatureView
        key={'signature'}
        userInfo={userInfo}
        isPrescription={true}
        isPad={isPad}
        prescriptionSetting={prescriptionSetting}
      />
    );
  };

  return (
    <div
      onClick={() => {
        closeModalOutside();
      }}
    >
      <Header />
      <div className="container-fluid">
        <PrescriptionTopContext.Provider
          value={{
            selectedAdvicesGroups,
            selectedComplainGroups,
            selectedDiagnosisGroups,
            selectedInfertilityManGroups,
            selectedMedicineGroups,
            selectedInvestigationGroups,
            selectedTemplates,
            patientInfo,
            toggleTemplateSelection,
          }}
        >
          <PrescriptionTop
            printRef={printRef}
            title={title}
            setTitle={setTitle}
            file={file}
            setFile={setFile}
            handlePrintStatus={handlePrintStatus}
            prescriptionId={prescriptionId}
            //patientInfo={patientInfo}
            note={note}
            setNote={setNote}
            openVideoModal={openVideoModal}
            setOpenVideoModal={setOpenVideoModal}
            onlineUserExist={onlineUserExist}
            setOnlineUserExist={setOnlineUserExist}
          />
        </PrescriptionTopContext.Provider>
        <Row className="rx-body">
          <small className="v-error">{getErrorMessage('name')}</small>
          <div className="col-xl-12 col-md-12 col-lg-12 col-sm-12">
            <div
              className={`card shadow mb-4 ${
                userInfo?.signaturePosition && isPad ? 'relative-card-body' : ''
              }`}
            >
              {/* card body normal view */}
              <div className="card-body normal-card-body">
                {loadingStatus && (
                  <div className="saved-image-over">
                    <img src={loadingImg} height={200} width={200} />
                  </div>
                )}
                <PrescriptionHeader
                  patientId={patientInfo?.id}
                  headerInfo={header}
                  prescriptionSetting={prescriptionSetting}
                />
                <PatientSection
                  patientPicture={patientPicture}
                  patientInfo={patientInfo}
                  patientList={patientList}
                  appointmentInfo={appointmentInfo}
                  prescriptionSetting={prescriptionSetting}
                  setPatientInfo={setPatientInfo}
                  selectedOnExamination={selectedOnExamination}
                  setSelectedOnExamination={setSelectedOnExamination}
                  actualWidth={actualWidth}
                  isPad={isPad}
                />
                <div className="row pl-2 pr-2 pb-3 prescription-body-print">
                  {leftOrRightItems(prescriptionItems, 'left').length > 0 && (
                    <div
                      className={`prescription-body-left ${
                        isPad ? '' : 'blank-prescription-print-left'
                      }`}
                      style={{ paddingLeft: '28px', width: '35%' }}
                    >
                      {leftOrRightItems(prescriptionItems, 'left').map(
                        (element) => {
                          if (element.name === 'chief-complain') {
                            return chiefComplainView(element);
                          } else if (element.name === 'treatment-plan') {
                            return treatmentPlanView(element);
                          } else if (element.name === 'history') {
                            return historyView(element);
                          } else if (element.name === 'on-examination') {
                            return onExaminationView(element);
                          } else if (element.name === 'diagnosis') {
                            return viewDiagnosis(element);
                          } else if (element.name === 'investigation') {
                            return investigationView(element);
                          } else if (element.name === 'rx') {
                            return viewRx(element);
                          } else if (element.name === 'rehabilitation') {
                            return viewRehabilitation(element);
                          } else if (element.name === 'advice') {
                            return adviceView(element);
                          } else if (element.name === 'follow-up') {
                            return followUpView(element);
                          } else if (element.name === 'eye-glass') {
                            return eyeGlassView(element);
                          } else if (element.name === 'infertility') {
                            return infertilityView(element);
                          } else if (element.name === 'special-note') {
                            return specialNoteView(element);
                          } else if (element.name === 'signature') {
                            return signatureView(element);
                          }
                          {
                            /* else if (element.name === 'predictions') {
                            return predictionsView(element);
                          } */
                          }
                        },
                      )}
                    </div>
                  )}
                  {leftOrRightItems(prescriptionItems, 'right').length > 0 && (
                    <div
                      className={`prescription-body-right ${
                        isPad ? '' : 'blank-prescription-print-right'
                      }`}
                      style={{ paddingLeft: '25px', width: '60%' }}
                    >
                      {leftOrRightItems(prescriptionItems, 'right').map(
                        (element) => {
                          if (element.name === 'chief-complain') {
                            return chiefComplainView(element);
                          } else if (element.name === 'treatment-plan') {
                            return treatmentPlanView(element);
                          } else if (element.name === 'history') {
                            return historyView(element);
                          } else if (element.name === 'on-examination') {
                            return onExaminationView(element);
                          } else if (element.name === 'diagnosis') {
                            return viewDiagnosis(element);
                          } else if (element.name === 'investigation') {
                            return investigationView(element);
                          } else if (element.name === 'rx') {
                            return viewRx(element);
                          } else if (element.name === 'rehabilitation') {
                            return viewRehabilitation(element);
                          } else if (element.name === 'advice') {
                            return adviceView(element);
                          } else if (element.name === 'follow-up') {
                            return followUpView(element);
                          } else if (element.name === 'eye-glass') {
                            return eyeGlassView(element);
                          } else if (element.name === 'infertility') {
                            return infertilityView(element);
                          } else if (element.name === 'special-note') {
                            return specialNoteView(element);
                          } else if (element.name === 'signature') {
                            return signatureView(element);
                          } else if (element.name === 'predictions') {
                            return predictionsView(element);
                          }
                        },
                      )}
                    </div>
                  )}
                </div>

                <hr style={{ backgroundColor: '#FBFBFB' }} className="hr" />
                <PrescriptionFooter
                  footerInfo={footer}
                  prescriptionSetting={prescriptionSetting}
                  printStatus={printStatus}
                />
              </div>
              ;{/* card body print view */}
              <div
                className={`
                  card-body print-card-body
                  ${isPad ? '' : 'blank-prescription-card-body'}`}
                style={
                  isPad ? pagePrintStyle(prescriptionSetting, actualWidth) : {}
                }
                ref={printRef}
              >
                <PrescriptionHeader
                  patientPicture={patientPicture}
                  patientId={patientInfo?.id}
                  headerInfo={header}
                  prescriptionSetting={prescriptionSetting}
                />
                <PatientSection
                  patientPicture={patientPicture}
                  patientInfo={patientInfo}
                  appointmentInfo={appointmentInfo}
                  patientList={patientList}
                  prescriptionSetting={prescriptionSetting}
                  setPatientInfo={setPatientInfo}
                  selectedOnExamination={selectedOnExamination}
                  setSelectedOnExamination={setSelectedOnExamination}
                  isPad={isPad}
                />
                <div className="row prescription-body-print">
                  {leftOrRightItems(prescriptionItems, 'left').length > 0 && (
                    <div
                      style={leftSidePrintStyle(isPad, prescriptionSetting)}
                      className={`prescription-body-left
                        ${isPad ? '' : 'blank-prescription-print-left'}`}
                    >
                      {leftOrRightItems(prescriptionItems, 'left').map(
                        (element) => {
                          if (element.name === 'chief-complain') {
                            return chiefComplainView(element, 'print');
                          } else if (element.name === 'treatment-plan') {
                            return treatmentPlanView(element, 'print');
                          } else if (element.name === 'treatment-lain') {
                            return historyView(element, 'print');
                          } else if (element.name === 'history') {
                            return historyView(element, 'print');
                          } else if (element.name === 'on-examination') {
                            return onExaminationView(element, 'print');
                          } else if (element.name === 'diagnosis') {
                            return viewDiagnosis(element, 'print');
                          } else if (element.name === 'investigation') {
                            return investigationView(element, 'print');
                          } else if (element.name === 'rx') {
                            return viewRx(element, 'print');
                          } else if (element.name === 'rehabilitation') {
                            return viewRehabilitation(element, 'print');
                          } else if (element.name === 'advice') {
                            return adviceView(element, 'print');
                          } else if (element.name === 'follow-up') {
                            return followUpView(element, 'print');
                          } else if (element.name === 'eye-glass') {
                            return eyeGlassView(element, 'print');
                          } else if (element.name === 'infertility') {
                            return infertilityView(element, 'print');
                          } else if (element.name === 'special-note') {
                            return specialNoteView(element, 'print');
                          } else if (
                            element.name === 'signature' &&
                            !userInfo?.signaturePosition
                          ) {
                            return signatureView(element, 'print');
                          }
                        },
                      )}
                    </div>
                  )}
                  {leftOrRightItems(prescriptionItems, 'right').length > 0 && (
                    <div
                      style={
                        isPad ? rightSidePrintStyle(prescriptionSetting) : {}
                      }
                      className={`prescription-body-right
                            ${isPad ? '' : 'blank-prescription-print-right'}`}
                    >
                      {leftOrRightItems(prescriptionItems, 'right').map(
                        (element) => {
                          if (element.name === 'chief-complain') {
                            return chiefComplainView(element, 'print');
                          } else if (element.name === 'treatment-plan') {
                            return treatmentPlanView(element, 'print');
                          } else if (element.name === 'history') {
                            return historyView(element, 'print');
                          } else if (element.name === 'on-examination') {
                            return onExaminationView(element, 'print');
                          } else if (element.name === 'diagnosis') {
                            return viewDiagnosis(element, 'print');
                          } else if (element.name === 'investigation') {
                            return investigationView(element, 'print');
                          } else if (element.name === 'rx') {
                            return viewRx(element, 'print');
                          } else if (element.name === 'rehabilitation') {
                            return viewRehabilitation(element, 'print');
                          } else if (element.name === 'advice') {
                            return adviceView(element, 'print');
                          } else if (element.name === 'follow-up') {
                            return followUpView(element, 'print');
                          } else if (element.name === 'eye-glass') {
                            return eyeGlassView(element, 'print');
                          } else if (element.name === 'infertility') {
                            return infertilityView(element, 'print');
                          } else if (element.name === 'special-note') {
                            return specialNoteView(element, 'print');
                          } else if (
                            element.name === 'signature' &&
                            !userInfo?.signaturePosition
                          ) {
                            return signatureView(element, 'print');
                          }
                        },
                      )}
                    </div>
                  )}
                </div>

                <BarcodeQrCode
                  patientInfo={patientInfo}
                  prescriptionId={prescriptionId}
                />

                <PrescriptionFooter
                  footerInfo={footer}
                  prescriptionSetting={prescriptionSetting}
                  printStatus={printStatus}
                />

                {userInfo?.signaturePosition &&
                  isPad &&
                  signatureView('element', 'print')}
              </div>
              ;;
            </div>
          </div>
        </Row>

        <ChiefComplainModal
          modalTitle={rxItemName('chief-complain')}
          isComplainModal={isComplainModal}
          setIsComplainModal={setIsComplainModal}
          selectedChiefComplains={selectedChiefComplains}
          setSelectedChiefComplains={setSelectedChiefComplains}
          selectedComplainGroups={selectedComplainGroups}
          setSelectedComplainGroups={setSelectedComplainGroups}
        />
        <TreatmentPlanModal
          modalTitle={rxItemName('Treatment Plan')}
          isTreatmentPlanModal={isTreatmentPlanModal}
          setIsTreatmentPlanModal={setIsTreatmentPlanModal}
          selectedTreatmentPlans={selectedTreatmentPlans}
          setSelectedTreatmentPlans={setSelectedTreatmentPlans}
          selectedTreatmentPlansGroups={selectedTreatmentPlansGroups}
          setSelectedTreatmentPlansGroups={setSelectedTreatmentPlansGroups}
        />
        <HistoryContext.Provider
          value={{
            selectedMedicines,
            setSelectedMedicines,
          }}
        >
          <HistoryModal
            modalTitle={rxItemName('history')}
            showHistory={showHistory}
            setShowHistory={setShowHistory}
            selectedHistories={selectedHistories}
            setSelectedHistories={setSelectedHistories}
            selectedHistoryTab={selectedHistoryTab}
            setSelectedHistoryTab={setSelectedHistoryTab}
          />
        </HistoryContext.Provider>
        {/* <InvestigationModal
          modalTitle={rxItemName('chief-complain')}
          showInvestigation={showInvestigation}
          setShowInvestigation={setShowInvestigation}
          selectedInvestigations={selectedInvestigations}
          setSelectedInvestigations={setSelectedInvestigations}
          selectedInvestigationGroups={selectedInvestigationGroups}
          setSelectedInvestigationGroups={setSelectedInvestigationGroups}
        /> */}
        <OnExaminationModal
          modalTitle={rxItemName('on-examination')}
          patientInfo={patientInfo}
          setPatientInfo={setPatientInfo}
          showOnExamination={showOnExamination}
          setShowOnExamination={setShowOnExamination}
          onExaminationModalClose={closeOnExaminationModal}
          handleOnExaminations={handleOnExaminations}
          selectedOnExamination={selectedOnExamination}
          selectedOnExaminationTab={selectedOnExaminationTab}
          setSelectedOnExaminationTab={setSelectedOnExaminationTab}
        />
        <PredictionsModal
          modalTitle={rxItemName('prediction')}
          patientInfo={patientInfo}
          setPatientInfo={setPatientInfo}
          showPredictions={showPredictions}
          setShowPredictions={setShowPredictions}
          onExaminationModalClose={closeOnExaminationModal}
          handleOnExaminations={handleOnExaminations}
          selectedOnExamination={selectedOnExamination}
          setSelectedOnExamination={setSelectedOnExamination}
          selectedOnExaminationTab={selectedOnExaminationTab}
          setSelectedOnExaminationTab={setSelectedOnExaminationTab}
        />
        <DiagnosisContext.Provider
          value={{
            selectedStage,
            setSelectedStage,
          }}
        >
          <DiagnosisModal
            modalTitle={rxItemName('diagnosis')}
            showDiagnosis={showDiagnosis}
            setShowDiagnosis={setShowDiagnosis}
            selectedDiagnosis={selectedDiagnosis}
            setSelectedDiagnosis={setSelectedDiagnosis}
            selectedDiagnosisTab={selectedDiagnosisTab}
            setSelectedDiagnosisTab={setSelectedDiagnosisTab}
            selectedDiagnosisGroups={selectedDiagnosisGroups}
            setSelectedDiagnosisGroups={setSelectedDiagnosisGroups}
          />
        </DiagnosisContext.Provider>
        <AdviceModal
          modalTitle={rxItemName('advice')}
          showAdvice={showAdvice}
          setShowAdvice={setShowAdvice}
          selectedAdvices={selectedAdvices}
          setSelectedAdvices={setSelectedAdvices}
          selectedAdvicesGroups={selectedAdvicesGroups}
          setSelectedAdvicesGroups={setSelectedAdvicesGroups}
        />
        <FollowUpModal
          modalTitle={rxItemName('follow-up')}
          showFollowUp={showFollowUp}
          setShowFollowUp={setShowFollowUp}
          selectedFollowUps={selectedFollowUps}
          setSelectedFollowUps={setSelectedFollowUps}
        />
        <RxContext.Provider
          value={{
            selectedMedicines,
            setSelectedMedicines,
            instructions,
            setInstructions,
            selectedMedicineGroups,
            setSelectedMedicineGroups,
            medicineGroups,
            setMedicineGroups,
          }}
        >
          <RxModal
            modalTitle={rxItemName('rx')}
            showRx={showRx}
            setShowRx={setShowRx}
            medicines={medicines}
            setMedicines={setMedicines}
            medicinesInSearch={medicinesInSearch}
            setMedicinesInSearch={setMedicinesInSearch}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalItem={totalItem}
            perPage={perPage}
          />
        </RxContext.Provider>
        <RehabilitationModal
          modalTitle={rxItemName('rehabilitation')}
          showRehabilitation={showRehabilitation}
          setShowRehabilitation={setShowRehabilitation}
          selectedRehabilitation={selectedRehabilitation}
          setSelectedRehabilitation={setSelectedRehabilitation}
        />
        <EyeGlassModal
          modalTitle={rxItemName('eye-glass')}
          showEyeGlass={showEyeGlass}
          setShowEyeGlass={setShowEyeGlass}
          selectedEyeGlass={selectedEyeGlass}
          setSelectedEyeGlass={setSelectedEyeGlass}
          deletePrescriptionItem={deletePrescriptionItem}
        />
        <InvestigationModal
          modalTitle={rxItemName('investigation')}
          showInvestigation={showInvestigation}
          setShowInvestigation={setShowInvestigation}
          selectedInvestigations={selectedInvestigations}
          setSelectedInvestigations={setSelectedInvestigations}
          selectedInvestigationGroups={selectedInvestigationGroups}
          setSelectedInvestigationGroups={setSelectedInvestigationGroups}
          selectedHistories={selectedHistories}
          setSelectedHistories={setSelectedHistories}
          isPrescriptionExist={location?.state?.history}
        />

        <InfertilityModal
          modalTitle={rxItemName('infertility')}
          showInfertility={showInfertility}
          setShowInfertility={setShowInfertility}
          selectedInfertilities={selectedInfertilities}
          setSelectedInfertilities={setSelectedInfertilities}
          selectedInfertilityWomenGroups={selectedInfertilityWomenGroups}
          setSelectedInfertilityWomenGroups={setSelectedInfertilityWomenGroups}
          selectedInfertilityManGroups={selectedInfertilityManGroups}
          setSelectedInfertilityManGroups={setSelectedInfertilityManGroups}
        />
        <SpecialNoteModal
          modalTitle={rxItemName('special-note')}
          showSpecialNote={showSpecialNote}
          setShowSpecialNote={setShowSpecialNote}
          selectedSpecialNotes={selectedSpecialNotes}
          setSelectedSpecialNotes={setSelectedSpecialNotes}
        />
      </div>
      <TemplateModal
        isTemplateModal={isTemplateModal}
        setIsTemplateModal={setIsTemplateModal}
        templateInput={{
          note: { note },
          organizationId: activeOrganization.id,
          chiefComplains: selectedChiefComplains,
          onExaminations: selectedOnExamination,
          diagnoses: selectedDiagnosis,
          staging: selectedStage,
          histories: selectedHistories,
          medicines: selectedMedicines,
          rehabilitation: selectedRehabilitation,
          advices: selectedAdvices,
          followUps: selectedFollowUps,
          investigations: selectedInvestigations,
          eyeGlass: selectedEyeGlass,
          specialNotes: selectedSpecialNotes,
          medicineIds: selectedMedicines.length
            ? selectedMedicines.map((item) => item.id)
            : [],
          // medicineIds: selectedMedicines.length
          //   ? selectedMedicines.map((item) => item.id)
          //   : [],
        }}
      />

      <PrescriptionSaveBtns
        loadingStatus={loadingStatus}
        resetPrescription={resetPrescription}
        handleSubmit={handleSubmit}
        isTemplateModal={isTemplateModal}
        setIsTemplateModal={setIsTemplateModal}
      />

      {openVideoModal && socket && (
        <VideoCallModal
          openVideoModal={openVideoModal}
          setOpenVideoModal={setOpenVideoModal}
          onlineUserExist={onlineUserExist}
          setOnlineUserExist={setOnlineUserExist}
          startCalling={startCalling}
          setStartCalling={setStartCalling}
          startVideo={startVideo}
          setStartVideo={setStartVideo}
          callTimeoutHandler={callTimeoutHandler}
          setCallTimeoutHandler={setCallTimeoutHandler}
          shouldClearTimeout={shouldClearTimeout}
          setShouldClearTimeout={setShouldClearTimeout}
          socket={socket}
          io={io}
          cancelCall={cancelCall}
          patientInfo={patientInfo}
          doctorInfo={userInfo}
        />
      )}
    </div>
  );
}

export default Prescription;
