import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Row, Col, Form, Tab } from 'react-bootstrap';
import DiagnosisGroupForm from './diagnosisGroupForm';
import {
  getRequest,
  postRequest,
  deleteRequest,
} from '../../../utils/axiosRequests';
import { checkDoctorDept } from '../../../utils/helpers';
import { useGetRequest } from '../../../hooks/useGetRequest';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import GroupWithDeleteIcon from '../partials/groupWithDeleteIcon';
import LoadMore from '../partials/loadMore';
import ModalHeader from '../partials/modalHeader';
import SearchArea from '../partials/searchArea';
import DiagnosisTabNav from '../partials/diagnosisTabNav';
import Staging from '../partials/staging';
import { handleGroupData } from '../../../utils/helpers';

const DiagnosisModal = ({
  modalTitle,
  selectedDiagnosis,
  setSelectedDiagnosis,
  selectedDiagnosisTab,
  setSelectedDiagnosisTab,
  selectedDiagnosisGroups,
  setSelectedDiagnosisGroups,
  showDiagnosis,
  setShowDiagnosis,
}) => {
  const [diagnoses, setDiagnoses] = useState([]);
  const [diagnosesInSearch, setDiagnosesInSearch] = useState([]);
  const [diagnosisGroups, setDiagnosisGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [searchItemName, setSearchItemName] = useState('');
  const perPage = 25;
  const userInfo = useSelector((state) => state.authReducer.data);
  const specialties = useSelector(
    (state) => state.specialtyReducer.specialties,
  );

  useEffect(() => {
    getRequest('diagnoses/groups')
      .then((data) => {
        setDiagnosisGroups(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const { isLoading: isDiagnosisLoading, refetch: getDiagnosisList } =
    useGetRequest(
      'getDiagnosis',
      `diagnoses?page=${currentPage}&perPage=${perPage}`,
      (data) => {
        if (currentPage > 1) {
          setDiagnoses([...diagnoses, ...data.data]);
        } else { console.log(999);
          setDiagnoses(data.data);
          setTotalItem(data.total);
        }
      },
      (e) => {
        console.log(e);
      },
    );

  const selectDiagnosis = (item) => {
    if (selectedDiagnosis.some((diagnosis) => diagnosis.name === item.name)) {
      setSelectedDiagnosis(
        selectedDiagnosis.filter((diagnosis) => diagnosis.name !== item.name),
      );
    } else {
      setSelectedDiagnosis([
        ...selectedDiagnosis,
        { name: item.name, query: false, note: '' },
      ]);
    }
  };

  const deleteDiagnosis = (diagnosisId) => {
    deleteRequest(`diagnoses/${diagnosisId}`)
      .then((data) => {
        setDiagnoses(
          diagnoses.filter((diagnosis) => diagnosis.id !== diagnosisId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteDiagnosisGroup = (groupId) => {
    deleteRequest(`diagnoses/groups/${groupId}`)
      .then((data) => {
        setDiagnosisGroups(
          diagnosisGroups.filter((group) => group.id !== groupId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectDiagnosisGroup = (group) => {
    handleGroupData(
      group,
      selectedDiagnosisGroups,
      setSelectedDiagnosisGroups,
      selectedDiagnosis,
      setSelectedDiagnosis,
      'Diagnosis group',
    );
  };

  const removeDiagnosis = (item) => {
    setSelectedDiagnosis(
      selectedDiagnosis.filter(
        (selectedItem) => selectedItem.name !== item.name,
      ),
    );
  };

  const handleDiagnosesExtra = (item, fieldName, value) => {
    const objIndex = selectedDiagnosis.findIndex(
      (diagnosis) => diagnosis.name == item.name,
    );
    selectedDiagnosis[objIndex][fieldName] = value;
    setSelectedDiagnosis([...selectedDiagnosis]);
  };

  let allDiagnose = diagnoses.map((item, index) => {
    let isSelected = selectedDiagnosis.find((data) => data.name === item.name);
    isSelected = isSelected ? true : false;

    return (
      <ItemWithDeleteIcon
        key={index}
        item={item}
        isSelected={isSelected}
        itemClickAction={selectDiagnosis}
        removeClickAction={deleteDiagnosis}
      />
    );
  });

  let groupWithMinusBtn = diagnosisGroups.map((group, index) => {
    let isSelected = selectedDiagnosisGroups.find(
      (data) => data.id === group.id,
    );
    isSelected = isSelected ? true : false;

    return (
      <GroupWithDeleteIcon
        key={index}
        item={group}
        isSelected={isSelected}
        itemClickAction={selectDiagnosisGroup}
        removeClickAction={deleteDiagnosisGroup}
      />
    );
  });

  // const handleSearchOrNew = (selectedOption) => {
  //   let selectedData = selectedOption;
  //   if (
  //     !selectedDiagnosis.some(
  //       (diagnosis) => diagnosis.name === selectedData.name,
  //     ) &&
  //     selectedData.id !== 'notFound'
  //   ) {
  //     setSelectedDiagnosis([...selectedDiagnosis, selectedData]);
  //   } else {
  //     postRequest('diagnoses', { name: selectedData?.name })
  //       .then((data) => {
  //         setSearchItemName('');
  //         setDiagnoses([...diagnoses, data]);
  //         setSelectedDiagnosis([
  //           ...selectedDiagnosis,
  //           { name: selectedData.name, query: false, note: '' },
  //         ]);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  //   setDiagnosesInSearch([]);
  //   setSearchQuery('');
  // };

  const handleSearchOrNew = async (selectedOption) => {
    try {
      const selectedData = selectedOption;
      const isExistingDiagnosis = selectedDiagnosis.some(
        (diagnosis) =>
          diagnosis?.name?.toLowerCase() === selectedData?.name?.toLowerCase(),
      );
      const filteredDiagnoses = isExistingDiagnosis
        ? selectedDiagnosis.filter(
            (item) =>
              item?.name?.toLowerCase() !== selectedData?.name?.toLowerCase(),
          )
        : [
            ...selectedDiagnosis,
            { name: selectedData.name, query: false, note: '' },
          ];
      setSelectedDiagnosis(filteredDiagnoses);
      if (selectedData?.id === 'notFound') {
        const data = await postRequest('diagnoses', {
          name: selectedData.name,
        });
        setDiagnoses([...diagnoses, data]);
        setSearchItemName('');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleOnInputChange = (searchKey) => {
  //   setIsLoading(true);
  //   const url = `diagnoses?name=${searchKey}`;
  //   setSearchQuery(searchKey);
  //   if (searchKey) {
  //     setSearchItemName(searchKey);
  //   }

  //   searchKey.length &&
  //     getRequest(url)
  //       .then((data) => {
  //         if (data?.data?.length > 0) {
  //           const customizedResults = data.data.map((item) => {
  //             return {
  //               ...item,
  //               label: item.name,
  //               value: item.name,
  //             };
  //           });
  //           setDiagnosesInSearch(customizedResults);
  //         } else {
  //           setDiagnosesInSearch([{ id: 'notFound', name: searchKey }]);
  //         }
  //         setIsLoading(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  // };
  const handleOnInputChange = (searchKey) => {
    setIsLoading(true);
    if (searchKey) {
      setSearchItemName(searchKey);
      setDiagnosesInSearch([]);

      const url = `diagnoses?name=${encodeURIComponent(searchKey)}`;
      setSearchQuery(searchKey);

      getRequest(url)
        .then((data) => {
          const customizedResults =
            data?.data?.length > 0
              ? data.data.map((item) => ({
                  ...item,
                  label: item.name,
                  value: item.name,
                }))
              : [{ id: 'notFound', name: searchKey }];

          setDiagnosesInSearch(customizedResults);
          setIsLoading(false);
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    getDiagnosisList();
  }, [currentPage]);

  const selectedDiagnosisList = () => {
    return selectedDiagnosis.map((item, index) => {
      return (
        <Row className="selected-item-row" key={index}>
          <Col lg={4} md={4} sm={5} xs={8}>
            {item.name}
          </Col>
          <Col lg={2} md={2} sm={2} xs={4} className="pr-0 responsive">
            <Form.Check
              type={`checkbox`}
              id={`query`}
              label={`Query`}
              checked={item.query}
              onChange={(evt) =>
                handleDiagnosesExtra(item, 'query', evt.target.checked)
              }
            />
          </Col>
          <Col lg={5} md={5} sm={4} xs={10} className="pr-0 responsive">
            <Form.Control
              size="sm"
              type="text"
              defaultValue={item.note}
              placeholder="Enter note"
              onChange={(evt) =>
                handleDiagnosesExtra(item, 'note', evt.target.value)
              }
            />
          </Col>
          <Col lg={1} md={1} sm={1} xs={1} className="text-right">
            <i
              className="fa fa-times-circle cursor-pointer pt-1"
              aria-hidden="true"
              onClick={() => removeDiagnosis(item)}
            ></i>
          </Col>
        </Row>
      );
    });
  };

  const isOncologyOrHematolgy = (specialityName) => {
    const result = checkDoctorDept(
      specialityName,
      userInfo?.speciality[0],
      specialties,
    );
    return result ? 'staging' : '';
  };

  return (
    <>
      <Modal
        show={showDiagnosis}
        size="lg"
        className="customize-modal-size"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ModalHeader title={modalTitle} action={setShowDiagnosis} />
        <Modal.Body>
          <SearchArea
            handleOnInputChange={handleOnInputChange}
            handleSearchOrNew={handleSearchOrNew}
            searchItemName={searchItemName}
            searchQuery={searchQuery}
            options={diagnosesInSearch}
            selectedInputs={selectedDiagnosis}
            placeholder={'diagnosis'}
          />
          <Tab.Container id="left-tabs-example" defaultActiveKey="all">
            <DiagnosisTabNav
              action={setIsGroupModal}
              selectedItems={selectedDiagnosis}
              setSelectedDiagnosisTab={setSelectedDiagnosisTab}
              type={
                isOncologyOrHematolgy('oncology') ||
                isOncologyOrHematolgy('hematology')
              }
            />
            <Tab.Content>
              <Tab.Pane eventKey="all" className="add-scroll">
                <Row className="complains-area mr-0 ml-0">{allDiagnose}</Row>
                <LoadMore
                  currentPage={currentPage}
                  totalItem={totalItem}
                  perPage={perPage}
                  currentPageAction={setCurrentPage}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="group" className="add-scroll">
                <Row className="complains-area mr-0 ml-0 mt-1">
                  {groupWithMinusBtn}
                </Row>
              </Tab.Pane>
              {selectedDiagnosisTab === 'staging' && (
                <Tab.Pane
                  eventKey="staging"
                  style={{ height: '390px' }}
                  className="add-scroll"
                >
                  <Staging />
                </Tab.Pane>
              )}
            </Tab.Content>
          </Tab.Container>

          <DiagnosisGroupForm
            modalTitle={modalTitle}
            isGroupModal={isGroupModal}
            setIsGroupModal={setIsGroupModal}
            itemNames={selectedDiagnosis.map((diagnosis) => diagnosis.name)}
            diagnosisGroups={diagnosisGroups}
            setDiagnosisGroups={setDiagnosisGroups}
            selectedDiagnosisGroups={selectedDiagnosisGroups}
            setSelectedDiagnosisGroups={setSelectedDiagnosisGroups}
          />
          <hr className="selected-hr" />
          {selectedDiagnosisTab !== 'staging' && (
            <>
              <div className="selected-item-title">Selected list</div>
              <div className="selected-item-area">
                {selectedDiagnosisList()}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default memo(DiagnosisModal);
