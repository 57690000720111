import React, { memo, useContext } from 'react';
import Select from 'react-select';
import { PrescriptionTopContext } from '../../../contexts/PrescriptionTopContext';

const SelectCustomTemplate = ({
  handleInputChange,
  options,
  onChange,
  components,
  placeholder,
  formatOptionLabel,
}) => {
  const {
    patientInfo,
    selectedMedicineGroups,
    selectedDiagnosisGroups,
    selectedComplainGroups,
    toggleTemplateSelection,
    selectedAdvicesGroups,
    selectedInvestigationGroups,
    selectedMedicalHistoryGroups,
    selectedTemplates,
  } = useContext(PrescriptionTopContext);

  const isSelectedTemp = (templateName, type) => {
    const groupMapping = {
      'Diagnosis group': selectedDiagnosisGroups,
      'Chief complain group': selectedComplainGroups,
      'Advice group': selectedAdvicesGroups,
      'Investigation group': selectedInvestigationGroups,
      //'Medical History group': selectedMedicalHistoryGroups,
      'Prescription Template': selectedTemplates,
      'Medicine group': selectedMedicineGroups,
    };
    const group = groupMapping[type];
    const result =
      (group && group?.some((template) => template.name === templateName)) ||
      false;

    let color = '#000000';
    let background = '#e6fff7';
    let fontWeight = 'normal';
    if (type === 'Patient') {
      background = '#e2f3fe';
    }
    if (result && type !== 'Patient') {
      color = '#ffffff';
      background = '#00b38c';
      fontWeight = 'bold';
    }
    const patient =
      (templateName && templateName === patientInfo.name) || false;
    if (patient && type === 'Patient') {
      color = '#ffffff';
      background = '#4e73df';
      fontWeight = 'bold';
    }
    return {
      color,
      background,
      fontWeight,
    };
  };

  const colorStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        paddingTop: '2px',
        borderBottom: '1px solid #ffffff',
        cursor: 'pointer',
        ...isSelectedTemp(data.name, data.type),
      };
    },
  };

  return (
    <Select
      classNamePrefix="Select Type"
      value={''}
      placeholder={
        placeholder
          ? `Search ${
              placeholder === 'template'
                ? placeholder
                : ` / Add a ${placeholder}`
            }`
          : ''
      }
      isClearable={true}
      isSearchable={true}
      name="type"
      formatOptionLabel={formatOptionLabel ? formatOptionLabel : ''}
      components={components}
      options={options}
      onInputChange={handleInputChange}
      onChange={onChange}
      styles={colorStyles}
    />
  );
};

export default memo(SelectCustomTemplate);
